<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-6 mt-5 mx-auto">
        <router-link to="/locations" class="btn btn-sm btn-primary mb-3"
          >Back</router-link
        >
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Add Location</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit">
              <div class="form-group row">
                <div class="col-md-12">
                  <label>Name <span class="text-danger">*</span></label>
                  <span class="text-danger d-block" v-if="errors.name">
                    {{ errors.name }}
                  </span>
                  <input
                    type="text"
                    class="form-control form-control-sm mb-3"
                    required
                    v-model="location.name"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mt-3">
                  <button type="submit" class="btn btn-sm btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";

export default {
  data() {
    return {
      location: {
        name: null,
        parentId: null,
        createdBy: localStorage.getItem("uid"),
      },
      errors: [],
      loading: false,
    };
  },
  methods: {
    submit() {
      this.loading = true;
      this.errors = [];
      const data = toRaw(this.location);
      this.$store
        .dispatch("locations/store", { data })
        .then(() => {
          this.loading = false;
          this.$toast.show("Successfuly added.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.location.name = null;
          this.errors = [];
        })
        .catch((e) => {
          this.loading = false;
          this.errors = e.error;
        });
    },
  },
};
</script>
