<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mt-5 mx-auto">
        <router-link to="/skillSet" class="btn btn-sm btn-primary mb-3"
          >Back</router-link
        >
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Add Skill-set</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit">
              <div class="form-group mt-3">
                <label>Worker</label>
                <multiselect
                  v-model="gig.user_id"
                  :options="workers"
                  label="full_name"
                ></multiselect>
              </div>
              <div class="form-group mt-3">
                <label>Title <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="gig.title"
                  required
                />
              </div>
              <div class="form-group mt-3">
                <label>City</label>
                <multiselect
                  v-model="gig.location_id"
                  :options="locations"
                  label="name"
                ></multiselect>
              </div>
              <div class="form-group mt-3">
                <label>Experience</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="gig.experience"
                  @keydown="numberOnly($event)"
                />
              </div>
              <div class="form-group mt-3">
                <label>Rate</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="gig.rate"
                  @keydown="numberOnly($event)"
                />
              </div>
              <div class="form-group mt-3">
                <label>Images</label>
                <input
                  type="file"
                  ref="pictures"
                  multiple
                  class="form-control"
                  accept="image/png, image/jpg, image/jpeg"
                  @change="selectPictures"
                />
                <div class="image-group row">
                  <div
                    class="col-md-4 mt-3"
                    v-for="(picture, index) in picturesPreview"
                  >
                    <div style="position: relative">
                      <img
                        :src="picture"
                        class="img-thumbnail"
                        style="width: 100%"
                      />
                      <button
                        type="button"
                        class="btn btn-sm btn-danger"
                        style="position: absolute; top: 0; right: 0"
                        @click="removePicture(index)"
                      >
                        X
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group mt-3">
                <label>Detail</label>
                <input type="text" class="form-control" v-model="gig.details" />
              </div>
              <div class="form-group mt-3">
                <label>Skill</label>
                <multiselect
                  v-model="gig.skill_id"
                  :options="skills"
                  label="name"
                ></multiselect>
              </div>
              <div class="form-group mt-3">
                <label>Upload Audio</label>
                <input
                  type="file"
                  ref="audio"
                  accept="audio/mp3"
                  class="form-control"
                  @change="selectAudio"
                />
                <audio
                  class="mt-3"
                  controls
                  id="audio"
                  v-show="audioPreview != null"
                ></audio>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label>Upload Video</label>
                  <small class="ms-2">(mp4)</small>
                  <input
                    type="file"
                    class="form-control"
                    ref="introVideo"
                    accept="video/mp4"
                    @change="videoSelected"
                  />
                  <video
                    class="mt-3 w-50"
                    id="video-preview"
                    controls
                    v-show="previewVideo"
                  ></video>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-9">
                  <button type="submit" class="btn btn-lg btn-primary w-100">
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <button
                    type="reset"
                    class="btn btn-lg btn-outline-danger w-100"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { isNumber, validateFileFormat } from "@/helpers/helpers";

export default {
  data() {
    return {
      loading: false,
      gig: {
        user_id: null,
        title: null,
        location_id: null,
        experience: null,
        rate: null,
        images: [],
        details: null,
        skill_id: null,
        audio: null,
        video: null,
      },
      picturesPreview: [],
      audioPreview: null,
      video: null,
      previewVideo: null,
      audioFile: null,
      locations: [],
      skills: [],
      workers: [],
    };
  },
  mounted() {
    this.fetchLocations();
    this.fetchSkills();
    this.fetchWorkers();
  },
  methods: {
    fetchWorkers() {
      this.loading = true;
      const type = "worker";
      this.$store
        .dispatch("userProfiles/getAll", { type })
        .then((response) => {
          this.loading = false;
          this.workers = response.data;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    submit() {
      this.loading = true;
      if (this.gig.skill_id) {
        this.gig.skill_id = this.gig.skill_id.id;
      }
      if (this.gig.location_id) {
        this.gig.location_id = this.gig.location_id.id;
      }
      this.gig.user_id = this.gig.user_id.id;
      const data = this.gig;
      this.$store
        .dispatch("skillSet/store", { data })
        .then((response) => {
          Object.keys(this.gig).forEach((key) => (this.gig[key] = null));
          this.gig.images = [];
          this.picturesPreview = [];
          this.audioPreview = null;
          this.previewVideo = null;
          this.loading = false;
          this.$toast.show("Successfully saved", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    videoSelected() {
      let video = document.getElementById("video-preview");
      this.previewVideo = this.$refs.introVideo.files[0];
      if (validateFileFormat(this.previewVideo, "video")) {
        this.$refs.introVideo.value = null;
        this.previewVideo = null;
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          video.src = e.target.result;
        };
        reader.onloadend = () => {
          this.gig.video = reader.result;
        };
        reader.readAsDataURL(this.previewVideo);
      }
    },
    numberOnly(evt) {
      isNumber(evt);
    },
    selectPictures() {
      const countPictures =
        this.$refs.pictures.files.length + this.picturesPreview.length;
      if (countPictures <= 3) {
        for (var i = 0; i < this.$refs.pictures.files.length; i++) {
          let picture = this.$refs.pictures.files[i];
          let reader = new FileReader();
          reader.onload = (e) => {
            this.picturesPreview.push(e.target.result);
          };
          reader.onloadend = () => {
            this.gig.images.push(reader.result);
          };
          reader.readAsDataURL(picture);
        }
      } else {
        this.$toast.show("You can upload max 3 pictures.", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      }
      this.$refs.pictures.value = null;
    },
    removePicture(index) {
      this.picturesPreview.splice(index, 1);
      this.gig.pictures.splice(index, 1);
    },
    selectAudio() {
      this.audioPreview = this.$refs.audio.files[0];
      let audio = document.getElementById("audio");
      let reader = new FileReader();
      reader.onload = (e) => {
        audio.src = e.target.result;
      };
      reader.onloadend = () => {
        this.gig.audio = reader.result;
      };
      reader.readAsDataURL(this.audioPreview);
    },
    fetchSkills() {
      this.loading = true;
      this.$store
        .dispatch("skills/getAllWithoutPagination")
        .then((response) => {
          this.skills = response.data;
          this.loading = false;
        });
    },
    fetchLocations() {
      this.loading = true;
      this.$store
        .dispatch("locations/getAllWithoutPagination")
        .then((response) => {
          this.locations = response.data;
          this.loading = false;
        });
    },
  },
};
</script>
