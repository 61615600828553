<template>
  <div>
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row widget-grid pt-5">
        <TotalUsersGrowthCard v-if="dashboardData" :data="dashboardData" />
        <RecentSalesCard v-if="dashboardData" :data="dashboardData" />
        <TimelineCard v-if="dashboardData" :data="dashboardData" />
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import RecentSalesCard from "./default/RecentSalesCard.vue";
import TimelineCard from "./default/TimelineCard.vue";
import TotalUsersGrowthCard from "./default/TotalUsersGrowthCard.vue";
import { apexDashboard } from "@/data/comon";

export default {
  components: {
    RecentSalesCard,
    TimelineCard,
    TotalUsersGrowthCard,
  },
  data() {
    return {
      apexDashboard: apexDashboard,
      msg: null,
      loading: false,
      dashboardData: null,
    };
  },
  mounted() {
    this.fetch();
    this.msg = atob(this.$route.params.msg);
    if (this.msg) {
      this.loading = true;
      const data = {
        admin_id: localStorage.getItem("uid"),
        module: this.msg,
        attempts: 1,
      };
      this.$store.dispatch("admins/activity", { data }).then((response) => {
        if (response.success == true) {
          this.$toast.show(response.data.message, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
          this.loading = false;
        } else {
          this.$toast.show(response.error.message, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
          this.$router.replace("/auth/login");
          localStorage.clear();
          this.loading = false;
        }
      });
    }
  },
  methods: {
    fetch() {
      this.$store
        .dispatch("dashboard/getAll")
        .then((response) => {
          this.dashboardData = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
