<template>
  <div class="col-xxl-4 col-md-6 appointment-sec box-col-6">
    <div class="appointment">
      <div class="card">
        <div class="card-header card-no-border">
          <div class="header-top">
            <h5 class="m-0">Recent Jobs</h5>
          </div>
        </div>
        <div class="card-body pt-0">
          <div class="appointment-table table-responsive">
            <table class="table table-bordernone">
              <tbody>
                <tr v-for="item in data.recent_jobs" :key="item">
                  <td class="img-content-box">
                    <a class="d-block f-w-500" href="#">{{
                      item.title
                    }}</a
                    ><span class="f-light">{{ item.employer.full_name }}</span>
                  </td>
                  <td class="text-end">
                    <p class="m-0 font-success">{{ item.salary }} PKR</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { resentSales } from "../../../data/dashboard/default";
import { apexDashboard } from "../../../data/comon";

export default {
  props: {
    data: null,
  },
  data() {
    return {
      apexDashboard: apexDashboard,
      resentSales: resentSales,
    };
  },
};
</script>
