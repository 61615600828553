<template>
  <div class="row">
    <div class="container-fluid mt-5">
      <div class="card">
        <div class="card-body">
          <h4 class="text-center">Advance Search</h4>
          <div class="row mt-3">
            <div class="col-md-2">
              <div class="form-group">
                <label>Workers</label>
                <multiselect
                  :options="workers"
                  label="full_name"
                  required
                ></multiselect>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Employers</label>
                <multiselect
                  :options="workers"
                  label="full_name"
                  required
                ></multiselect>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Company</label>
                <multiselect
                  :options="workers"
                  label="full_name"
                  required
                ></multiselect>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>From</label>
                <input type="date" class="form-control" style="height: 46px" />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>To</label>
                <input type="date" class="form-control" style="height: 46px" />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label></label>
                <button
                  class="btn btn-primary"
                  style="height: 46px; margin-top: 28px"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <data-table
              :columns="columns"
              :module="module"
              :items="items"
              :viewDetail="viewDetail"
            ></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataTable from "@/components/dataTable.vue";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      viewDetail: true,
      workers: [],
      items: [
        {
          title: "Need a carpenter",
          date: "06-03-2024",
          status: "In progress",
        },
      ],
      columns: [
        {
          text: "Job Title",
          value: "title",
        },
        {
          text: "Posted At",
          value: "date",
        },
        {
          text: "Status",
          value: "status",
        },
      ],
    };
  },
};
</script>
