<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mt-5 mx-auto">
        <router-link
          v-if="changePassword"
          to="/admins"
          class="btn btn-sm btn-primary mb-3"
          >Back</router-link
        >
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Change Password</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="editUser">
              <div class="form-group row" v-if="!changePassword">
                <div class="col-md-12">
                  <label>Old Password <span class="text-danger">*</span></label>
                  <span
                    class="text-danger d-block"
                    v-if="errors.old_password"
                    >{{ errors.old_password }}</span
                  >
                  <input
                    type="password"
                    class="form-control mb-3"
                    required
                    v-model="user.oldPassword"
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12">
                  <label>New Password <span class="text-danger">*</span></label>
                  <input
                    type="password"
                    class="form-control mb-3"
                    required
                    v-model="user.password"
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12">
                  <label
                    >Confirm Password <span class="text-danger">*</span></label
                  >
                  <span
                    class="text-danger d-block"
                    v-if="errors.confirmPassword"
                    >{{ errors.confirmPassword }}</span
                  >
                  <input
                    type="password"
                    class="form-control mb-3"
                    required
                    v-model="user.confirmPassword"
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-9">
                  <button type="submit" class="btn btn-lg btn-primary w-100">
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <button
                    type="reset"
                    class="btn btn-lg btn-outline-danger w-100"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";

export default {
  data() {
    return {
      user: {
        password: null,
        oldPassword: null,
        confirmPassword: null,
      },
      roles: [],
      errors: [],
      userId: null,
      loading: false,
      changePassword: false,
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.userId = this.$route.params.id;
      this.changePassword = true;
    } else {
      this.userId = localStorage.getItem("uid");
    }
  },
  methods: {
    submit() {
      if (this.user.password != this.user.confirmPassword) {
        this.errors.confirmPassword = "Password didn't match.";
      } else {
        this.errors = [];
        this.loading = true;
        const data = toRaw(this.user);
        const id = this.userId;
        this.$store
          .dispatch("admins/update", { data, id })
          .then(() => {
            this.loading = false;
            this.$toast.show("Successfuly updated.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            this.user.password = null;
            this.user.oldPassword = null;
            this.user.confirmPassword = null;
            this.errors = [];
          })
          .catch((e) => {
            this.errors = e.error;
            this.loading = false;
          });
      }
    },
  },
};
</script>
