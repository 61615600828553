<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mt-5 mx-auto">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Profile</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="editUser">
              <div class="form-group row">
                <div class="col-md-6">
                  <label>First Name <span class="text-danger">*</span></label>
                  <span class="text-danger d-block" v-if="errors.f_name">
                    First Name is required
                  </span>
                  <input
                    type="text"
                    class="form-control mb-3"
                    required
                    v-model="user.f_name"
                  />
                </div>
                <div class="col-md-6">
                  <label>Last Name</label>
                  <input
                    type="text"
                    class="form-control mb-3"
                    v-model="user.l_name"
                  />
                </div>
              </div>
              <div class="form-group">
                <label>Phone</label>
                <input
                  type="text"
                  class="form-control mb-3"
                  v-model="user.phone"
                  maxlength="11"
                  placeholder="03011234567"
                  @keypress="numberOnly($event)"
                />
              </div>
              <div class="form-group">
                <label>Email <span class="text-danger">*</span></label>
                <span class="text-danger d-block" v-if="errors.email">
                  {{ errors.email[0] }}
                </span>
                <input
                  type="email"
                  class="form-control mb-3"
                  required
                  v-model="user.email"
                  pattern="[^\s@]+@[^\s@]+\.[^\spatt@]+"
                />
              </div>
              <div class="row mt-3">
                <div class="col-md-9">
                  <button type="submit" class="btn btn-lg btn-primary w-100">
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <button
                    type="reset"
                    class="btn btn-lg btn-outline-danger w-100"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import { isNumber } from "@/helpers/helpers";

export default {
  data() {
    return {
      user: {
        f_Name: null,
        l_name: null,
        phone: null,
        email: null,
      },
      roles: [],
      errors: [],
      userId: null,
      loading: false,
    };
  },
  mounted() {
    this.userId = localStorage.getItem("uid");
    this.fetch(this.userId);
  },
  methods: {
    numberOnly(evt) {
      isNumber(evt);
    },
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("admins/getSingle", { id })
        .then((response) => {
          this.user = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    submit() {
      this.loading = true;
      this.errors = [];
      const data = toRaw(this.user);
      const id = this.userId;
      this.$store
        .dispatch("admins/update", { data, id })
        .then(() => {
          this.loading = false;
          this.$toast.show("Successfuly updated.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.errors = [];
        })
        .catch((e) => {
          this.errors = e.error;
          this.loading = false;
        });
    },
  },
};
</script>
