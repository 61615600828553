<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="card mt-5">
        <div class="card-body">
          <h3 class="text-center">Skills</h3>
          <div class="col-md-12 d-flex justify-content-end">
            <button
              v-if="permissions.includes('skill_add')"
              type="button"
              class="btn btn-sm btn-primary mb-2"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
            >
              Add Skill
            </button>
          </div>
          <div class="col-md-12">
            <data-table
              :columns="columns"
              :items="items"
              :module="module"
              :isEdit="isEdit"
              :isDelete="isDelete"
              @deleted="fetch"
              @nextPage="nextPage"
              @search="fetch"
              @clear="fetch"
            ></data-table>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="addModal"
      tabindex="-1"
      aria-labelledby="addModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addModalLabel">Add Skill</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submit" ref="addSkill">
              <div class="form-group row">
                <div class="col-md-12">
                  <label>Name <span class="text-danger">*</span></label>
                  <span class="text-danger d-block" v-if="errors.name">
                    {{ errors.name }}
                  </span>
                  <input
                    type="text"
                    class="form-control mb-3"
                    required
                    v-model="skill.name"
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-9">
                  <button type="submit" class="btn btn-lg btn-primary w-100">
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <button
                    type="reset"
                    class="btn btn-lg btn-outline-danger w-100"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataTable from "@/components/dataTable.vue";
import { toRaw } from "vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      items: [],
      columns: [
        {
          text: "Name",
          value: "name",
        },
      ],
      skill: {
        name: null,
        parentId: null,
        createdBy: localStorage.getItem("uid"),
      },
      errors: [],
      loading: false,
      module: "skills",
      permissions: [],
      isEdit: false,
      isDelete: false,
    };
  },
  created() {
    this.permissions = localStorage.getItem("permissions");
    if (this.permissions.includes("skill_edit")) {
      this.isEdit = true;
    }
    if (this.permissions.includes("skill_delete")) {
      this.isDelete = true;
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    nextPage(url) {
      this.loading = true;
      axiosInstance.get(url).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
    submit() {
      this.loading = true;
      this.errors = [];
      const data = toRaw(this.skill);
      this.$store
        .dispatch("skills/store", { data })
        .then(() => {
          this.fetch();
          this.loading = false;
          this.$toast.show("Successfuly added.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.skill.name = null;
          this.errors = [];
        })
        .catch((e) => {
          this.loading = false;
          this.errors = e.error;
        });
    },
    fetch(search = "") {
      this.loading = true;
      this.$store
        .dispatch("skills/getAll", { search })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>
