<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-10 mt-5 mx-auto">
        <button @click="$router.go(-1)" class="btn btn-sm btn-primary mb-3">
          Back
        </button>
        <div class="card mt-5">
          <div class="card-body">
            <h3 class="text-center">Form Submissions</h3>
            <div class="col-md-12">
              <data-table
                :columns="columns"
                :items="items"
                :module="module"
                :viewSubmission="true"
                @deleted="fetch"
                @nextPage="nextPage"
                @search="fetch"
                @clear="fetch"
              ></data-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dataTable from "@/components/dataTable.vue";

export default {
  components: {
    dataTable,
  },
  data() {
    return {
      loading: false,
      items: [],
      columns: [
        {
          text: "Name",
          value: "user",
          secondValue: "full_name",
        },
        {
          text: "Age",
          value: "user",
          secondValue: "age",
        },
        {
          text: "Gender",
          value: "user",
          secondValue: "gender",
        },
      ],
      module: "submission",
      formId: null,
    };
  },
  mounted() {
    this.formId = this.$route.params.id;
    this.fetch();
  },
  methods: {
    nextPage(url) {
      this.loading = true;
      axiosInstance.get(url).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
    fetch(search = "") {
      this.loading = true;
      const formId = this.formId;
      this.$store
        .dispatch("form/formSubmission", { formId })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>
