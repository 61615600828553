<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="card mt-5">
        <div class="card-body">
          <h3 class="text-center">Feedbacks</h3>
          <div class="col-md-12">
            <data-table
              :columns="columns"
              :items="items"
              :isEdit="isEdit"
              :isDelete="isDelete"
            ></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataTable from "@/components/dataTable.vue";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      columns: [
        {
          text: "User",
          value: "user_profile",
          secondValue: "full_name",
        },
        {
          text: "Detail",
          value: "detail",
        },
      ],
      items: [],
      loading: false,
      isEdit: false,
      isDelete: false,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$store
        .dispatch("feedback/getFeedbacks")
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>
