<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="card mt-5">
        <div class="card-body">
          <h3 class="text-center">Admins</h3>
          <div class="col-md-12 d-flex justify-content-end">
            <router-link
              to="/admins/add"
              class="btn btn-sm btn-primary mb-2"
              v-if="permissions.includes('admin_add')"
              >Add Admin</router-link
            >
          </div>
          <div class="col-md-12">
            <data-table
              :columns="columns"
              :module="module"
              :items="items"
              :isChangePassword="changePassword"
              :isEdit="isEdit"
              :isApprove="isApprove"
              :isBlock="isBlock"
              @deleted="fetch"
              @nextPage="nextPage"
              @search="fetch"
              @clear="fetch"
            ></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      searchValue: "",
      columns: [
        {
          text: "Name",
          value: "f_name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Phone",
          value: "phone",
        },
        {
          text: "Status",
          value: "status",
        },
      ],
      items: [],
      module: "admins",
      loading: false,
      changePassword: false,
      permissions: [],
      isEdit: false,
      isDelete: false,
      isBlock: true,
      isApprove: true,
    };
  },
  created() {
    this.permissions = localStorage.getItem("permissions");
    if (this.permissions.includes("admin_edit")) {
      this.isEdit = true;
    }
    if (this.permissions.includes("admin_block")) {
      this.isDelete = true;
    }
    if (this.permissions.includes("admin_change_password")) {
      this.changePassword = true;
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    nextPage(url) {
      this.loading = true;
      axiosInstance.get(url).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
    fetch(search = "") {
      this.loading = true;
      const id = localStorage.getItem("uid");
      this.$store
        .dispatch("admins/getAll", { id, search })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
  },
};
</script>
