<template>
  <div class="container-fluid">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5">
        <router-link to="/users" class="btn btn-sm btn-primary mb-3"
          >Back</router-link
        >
        <form
          @submit.prevent="submit"
          class="needs-validation"
          novalidate
          id="create-form"
          autocomplete="off"
        >
          <input type="text" name="dummy_field" style="display: none" />
          <div class="card">
            <div class="card-body">
              <!-- Registration -->
              <div class="col-md-12" v-if="currentTabIndex == 1">
                <h4 class="text-center">Registration</h4>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <div class="d-flex justify-content-between">
                      <label>CNIC</label>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :true-value="1"
                          :false-value="0"
                          id="cnicApprove"
                          v-if="user.profile"
                          v-model="user.profile.is_cnic"
                        />
                        <label class="form-check-label" for="cnicApprove">
                          Approve
                        </label>
                      </div>
                    </div>
                    <input
                      type="text"
                      v-model="user.cnic"
                      class="form-control"
                      @keydown="numberOnly($event)"
                      required
                      minlength="13"
                      maxlength="13"
                      autocomplete="new-cnic"
                    />
                    <div class="invalid-feedback">
                      CNIC length must be 13 digits
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label>Password</label>
                    <input
                      type="password"
                      v-model="user.password"
                      class="form-control"
                      autocomplete="new-password"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label>Re-Type Password</label>
                    <span
                      class="text-danger d-block"
                      v-if="errors.confirmPassword"
                    >
                      {{ errors.confirmPassword }}
                    </span>
                    <input
                      type="password"
                      v-model="user.confirmPassword"
                      class="form-control"
                      autocomplete="new-password"
                    />
                  </div>
                </div>
              </div>

              <!-- Basic Information -->
              <div class="col-md-12" v-if="currentTabIndex == 2">
                <h4 class="text-center">Basic Information</h4>
                <div
                  class="d-flex mt-3 justify-content-center align-items-center"
                >
                  <img
                    :src="previewProfilePic"
                    class="img-thumbnail"
                    style="width: 100px; height: 100px"
                  />
                  <div class="ms-3" style="width: 84%">
                    <label>Upload Profile Picture</label>
                    <small class="ms-2">(jpeg, jpg, png)</small>
                    <input
                      type="file"
                      ref="profilePic"
                      class="form-control"
                      accept="image/png, image/jpg, image/jpeg"
                      @change="profilePicSelected"
                    />
                  </div>
                </div>
                <div class="form-group row mt-3">
                  <div class="col-md-6">
                    <label>First Name <span class="text-danger">*</span></label>
                    <input
                      type="text"
                      class="form-control mb-3"
                      v-model="user.profile.f_name"
                      required
                    />
                  </div>
                  <div class="col-md-6">
                    <label>Last Name</label>
                    <input
                      type="text"
                      class="form-control mb-3"
                      v-model="user.profile.l_name"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12">
                    <label>Father Name</label>
                    <input
                      type="text"
                      v-model="user.profile.father_name"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-6 mt-3">
                    <label>CNIC Front Image</label>
                    <small class="ms-2">(jpeg, jpg, png)</small>
                    <input
                      type="file"
                      class="form-control"
                      ref="cnicfront"
                      accept="image/png, image/jpg, image/jpeg"
                      @change="cnicFrontSelected"
                    />
                    <img
                      v-if="previewCnicFront"
                      :src="previewCnicFront"
                      class="img-thumbnail mt-3"
                    />
                  </div>
                  <div class="col-md-6 mt-3">
                    <label>CNIC Back Image</label>
                    <small class="ms-2">(jpeg, jpg, png)</small>
                    <input
                      type="file"
                      class="form-control"
                      ref="cnicback"
                      accept="image/png, image/jpg, image/jpeg"
                      @change="cnicBackSelected"
                    />
                    <img
                      v-if="previewCnicBack"
                      :src="previewCnicBack"
                      class="img-thumbnail mt-3"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-6 mt-3">
                    <label>Date of Birth</label>
                    <input
                      type="date"
                      v-model="user.profile.dob"
                      class="form-control"
                    />
                  </div>
                  <div class="col-md-6 mt-3">
                    <label>Age</label>
                    <input
                      type="text"
                      v-model="user.profile.age"
                      class="form-control"
                      @keydown="numberOnly($event)"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <div class="d-flex justify-content-between">
                      <label>Phone <span class="text-danger">*</span></label>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :true-value="1"
                          :false-value="0"
                          id="phoneApprove"
                          v-model="user.profile.is_phone_number"
                        />
                        <label class="form-check-label" for="phoneApprove">
                          Approve
                        </label>
                      </div>
                    </div>
                    <input
                      type="text"
                      v-model="user.phone"
                      class="form-control"
                      maxlength="11"
                      @keydown="numberOnly($event)"
                      required
                    />
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label>Email <span class="text-danger">*</span></label>
                    <input
                      type="email"
                      v-model="user.email"
                      class="form-control"
                      pattern="[^\s@]+@[^\s@]+\.[^\spatt@]+"
                      required
                    />
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label>Gender <span class="text-danger">*</span></label>
                    <div class="col-md-12">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="male"
                          value="Male"
                          v-model="user.profile.gender"
                          requried
                        />
                        <label class="form-check-label" for="male">Male</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="female"
                          value="Female"
                          v-model="user.profile.gender"
                          requried
                        />
                        <label class="form-check-label" for="female"
                          >Female</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="other"
                          value="Other"
                          v-model="user.profile.gender"
                          requried
                        />
                        <label class="form-check-label" for="other"
                          >Others</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label>Address</label>
                    <input
                      type="text"
                      v-model="user.profile.address"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="form-group mt-3" v-if="isWorker">
                  <label>Locations where you want to work</label>
                  <multiselect
                    v-model="user.profile.locations"
                    placeholder="Select Locations"
                    label="name"
                    :multiple="true"
                    :max="3"
                    :options="locations"
                    :taggable="true"
                    :close-on-select="false"
                  ></multiselect>
                </div>
                <div class="form-group mt-3" v-if="isWorker">
                  <label>Skills/Profession</label>
                  <multiselect
                    v-model="user.profile.skills"
                    placeholder="Select Skills"
                    label="name"
                    :multiple="true"
                    :max="3"
                    :options="skills"
                    :taggable="true"
                    :close-on-select="false"
                  ></multiselect>
                </div>
                <div class="form-group mt-3">
                  <label>Qualification</label>
                  <multiselect
                    v-model="user.profile.qualification"
                    placeholder="Select Qualification"
                    label="name"
                    :options="qualifications"
                    :taggable="true"
                    :required="true"
                  ></multiselect>
                </div>
                <div class="form-group" v-if="isWorker">
                  <div class="col-md-12 mt-3">
                    <label>Self Introductory Video</label>
                    <small class="ms-2">(mp4)</small>
                    <input
                      type="file"
                      class="form-control"
                      ref="introVideo"
                      accept="video/mp4"
                      @change="introVideoSelected"
                    />
                    <video
                      v-show="videoSource"
                      class="mt-3 w-50"
                      id="video-preview"
                      ref="videoPreview"
                      controls
                    ></video>
                  </div>
                </div>
                <div class="form-group" v-if="isWorker">
                  <div class="col-md-12 mt-3">
                    <div class="d-flex justify-content-between">
                      <label
                        >Charactor Certificate by Police
                        <small class="ms-2">(jpeg, jpg, png)</small></label
                      >
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :true-value="1"
                          :false-value="0"
                          id="policeApprove"
                          v-model="user.profile.is_character_certificate"
                        />
                        <label class="form-check-label" for="policeApprove">
                          Approve
                        </label>
                      </div>
                    </div>
                    <input
                      type="file"
                      class="form-control"
                      ref="policeCertificate"
                      accept="image/png, image/jpg, image/jpeg"
                      @change="policeCertificateSelected"
                    />
                    <img
                      v-if="previewPoliceCertificate"
                      :src="previewPoliceCertificate"
                      class="img-thumbnail mt-3"
                      style="width: 50%"
                    />
                  </div>
                </div>
                <div class="form-group" v-if="isWorker">
                  <div class="col-md-12 mt-3">
                    <div class="d-flex justify-content-between">
                      <label
                        >Skill Certificate
                        <small class="ms-2">(jpeg, jpg, png)</small></label
                      >
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :true-value="1"
                          :false-value="0"
                          id="skillApprove"
                          v-model="user.profile.is_skill_certificate"
                        />
                        <label class="form-check-label" for="skillApprove">
                          Approve
                        </label>
                      </div>
                    </div>
                    <input
                      type="file"
                      class="form-control"
                      ref="skillCertificate"
                      accept="image/png, image/jpg, image/jpeg"
                      @change="skillCertificateSelected"
                    />
                    <img
                      v-if="previewSkillCertificate"
                      :src="previewSkillCertificate"
                      class="img-thumbnail mt-3"
                      style="width: 50%"
                    />
                  </div>
                </div>
              </div>

              <!-- Experiences -->
              <template v-if="!isEmployer">
                <div class="col-md-12" v-if="currentTabIndex == expTab">
                  <h4 class="text-center">Experiences</h4>
                  <div class="form-group mb-3">
                    <label>Total Experience</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="user.profile.experience"
                      @keydown="numberOnly($event)"
                    />
                  </div>
                  <div class="col-md-12">
                    <div class="d-flex justify-content-end">
                      <button
                        type="button"
                        class="btn btn-sm btn-primary"
                        @click="addNewExperience"
                      >
                        Add
                      </button>
                    </div>
                    <div
                      v-for="(experience, index) in user.profile.experiences"
                      class="mt-2"
                    >
                      <div class="d-flex justify-content-between">
                        <h6>Experience {{ index + 1 }}</h6>
                        <button
                          type="button"
                          class="btn btn-sm btn-outline-danger"
                          @click="removeExperience(index)"
                        >
                          Remove
                        </button>
                      </div>
                      <div class="form-group row">
                        <div class="col-md-6">
                          <label>Job Category</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="experience.job_category"
                          />
                        </div>
                        <div class="col-md-6">
                          <label>Company Name</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="experience.company_name"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="col-md-12 mt-3">
                          <label>Email</label>
                          <input
                            type="text"
                            v-model="experience.email"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="form-group row mt-3">
                        <div class="col-md-6">
                          <label>Start Date</label>
                          <input
                            type="date"
                            v-model="experience.start_date"
                            class="form-control"
                          />
                        </div>
                        <div class="col-md-6">
                          <label>End Date</label>
                          <input
                            type="date"
                            v-model="experience.end_date"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="col-md-12 mt-3">
                          <label>Address</label>
                          <input
                            type="text"
                            v-model="experience.address"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="col-md-12 mt-3">
                          <label>Job Type</label>
                          <input
                            type="text"
                            v-model="experience.job_type"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <!-- References -->
              <template v-if="!isCompany">
                <div class="col-md-12" v-if="currentTabIndex == refTab">
                  <h4 class="text-center">References</h4>
                  <div class="col-md-12">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :true-value="1"
                        :false-value="0"
                        id="refApprove"
                        v-model="user.profile.is_references"
                      />
                      <label class="form-check-label" for="refApprove">
                        Approve All References
                      </label>
                    </div>
                    <div class="d-flex justify-content-end">
                      <button
                        type="button"
                        class="btn btn-sm btn-primary"
                        @click="addNewReference"
                      >
                        Add
                      </button>
                    </div>
                    <div
                      v-for="(reference, index) in user.profile.references"
                      class="mt-2"
                    >
                      <div class="d-flex justify-content-between">
                        <h6>Reference {{ index + 1 }}</h6>
                        <button
                          type="button"
                          class="btn btn-sm btn-outline-danger"
                          @click="removeReference(index)"
                        >
                          Remove
                        </button>
                      </div>
                      <div class="form-group">
                        <div class="col-md-12">
                          <label>Full Name</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="reference.f_name"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="col-md-12 mt-3">
                          <label>Phone</label>
                          <input
                            type="text"
                            v-model="reference.phone"
                            class="form-control"
                            maxlength="11"
                            @keydown="numberOnly($event)"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="col-md-12 mt-3">
                          <label>Relation</label>
                          <input
                            type="text"
                            v-model="reference.relation"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="col-md-12 mt-3">
                          <label>Designation</label>
                          <input
                            type="text"
                            v-model="reference.designation"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <!-- Company Information -->
              <div class="col-md-12" v-if="currentTabIndex == comTab">
                <h4 class="text-center">Company Information</h4>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label
                      >Company Name <span class="text-danger">*</span></label
                    >
                    <span class="d-block text-danger" v-for="error in errors">{{
                      error.name
                    }}</span>
                    <input
                      type="text"
                      v-model="user.profile.company.name"
                      class="form-control"
                      required
                    />
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <div class="d-flex justify-content-between">
                      <label>Company Phone</label>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :true-value="1"
                          :false-value="0"
                          id="companyPhone"
                          v-if="user.profile"
                          v-model="user.profile.company.is_phone"
                        />
                        <label class="form-check-label" for="companyPhone">
                          Approve
                        </label>
                      </div>
                    </div>
                    <input
                      type="text"
                      v-model="user.profile.company.phone"
                      class="form-control"
                      maxlength="11"
                      @keydown="numberOnly($event)"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label>Official Website</label>
                    <input
                      type="text"
                      v-model="user.profile.company.website"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <div class="d-flex justify-content-between">
                      <label
                        >Registration No.
                        <span class="text-danger">*</span></label
                      >
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :true-value="1"
                          :false-value="0"
                          id="regNo"
                          v-if="user.profile"
                          v-model="user.profile.company.is_reg_no"
                        />
                        <label class="form-check-label" for="regNo">
                          Approve
                        </label>
                      </div>
                    </div>
                    <span class="d-block text-danger" v-for="error in errors">{{
                      error.reg
                    }}</span>
                    <input
                      type="text"
                      v-model="user.profile.company.reg_no"
                      class="form-control"
                      required
                    />
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label>Business Category</label>
                    <select
                      v-model="user.profile.company.category_id"
                      class="select-form-control"
                    >
                      <option value="">Select...</option>
                      <option
                        v-for="category in categories"
                        :value="category.id"
                      >
                        {{ category.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            v-if="!lastTab"
            type="button"
            class="btn btn-primary float-end mb-3"
            @click="nextTab"
          >
            Next
          </button>
          <button
            v-if="lastTab"
            type="submit"
            class="btn btn-primary float-end mb-3"
          >
            Done
          </button>
          <button
            type="button"
            class="btn btn-primary float-start mb-3"
            @click="prevTab"
            v-if="currentTabIndex > 1"
          >
            Previous
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import { isNumber, validateFileFormat } from "@/helpers/helpers";

export default {
  data() {
    return {
      loading: false,
      errors: [],
      reference: {
        fName: null,
        lName: null,
        phone: null,
        relation: null,
        designation: null,
      },
      experience: {
        jobCategory: null,
        companyName: null,
        email: null,
        startDate: null,
        endDate: null,
        address: null,
        jobType: null,
      },
      isNewReg: true,
      user: [],
      companies: [],
      isUsers: false,
      isCompanies: false,
      profilePicture: null,
      previewProfilePic: null,
      cnicFront: null,
      previewCnicFront: null,
      cnicBack: null,
      previewCnicBack: null,
      introVideo: null,
      previewIntroVideo: null,
      policeCertificate: null,
      previewPoliceCertificate: null,
      skillCertificate: null,
      previewSkillCertificate: null,
      categories: [],
      locations: [],
      skills: [],
      qualifications: [],
      currentTabIndex: 0,
      expTab: 3,
      refTab: 4,
      comTab: 5,
      currentTabIndex: 1,
      lastTab: false,
      lastTabIndex: 4,
      comReqFields: {
        name: false,
        regNo: false,
      },
      isEmployer: false,
      isCompany: false,
      isWorker: true,
      profile: {
        fName: null,
        lName: null,
        fatherName: null,
        cnicFrontImage: null,
        cnicBackImage: null,
        gender: null,
        dob: null,
        age: null,
        address: null,
        type: 1,
        companyId: null,
        qualificationId: null,
        locations: [],
        skills: [],
        introVideo: null,
        policeCertificate: null,
        skillCertificate: null,
        profilePic: null,
        experience: null,
      },
      videoSource: null,
    };
  },
  mounted() {
    this.userId = this.$route.params.id;
    this.type = this.$route.query.type;
    if (this.type == "Employer") {
      this.expTab = 4;
      this.refTab = 3;
      this.lastTabIndex = 3;
      this.isEmployer = true;
      this.isWorker = false;
    }
    if (this.type == "Company") {
      this.expTab = 4;
      this.refTab = 5;
      this.comTab = 3;
      this.lastTabIndex = 3;
      this.comReqFields.name = true;
      this.comReqFields.regNo = true;
      this.isCompany = true;
      this.isWorker = false;
    }
    this.fetch(this.userId);
    this.fetchCategories();
    this.fetchLocations();
    this.fetchSkills();
    this.fetchQualifications();
  },
  methods: {
    nextTab() {
      const form = document.getElementById("create-form");
      if (form.checkValidity() === true) {
        if (this.user.password != this.user.confirmPassword) {
          this.errors.confirmPassword = "Password didn't match";
        } else {
          this.currentTabIndex += 1;
          if (this.currentTabIndex == this.lastTabIndex) {
            this.lastTab = true;
          }
        }
        this.$nextTick(() => {
          let video = this.$refs.videoPreview;
          if (video) {
            if (this.videoSource) {
              video.src = this.videoSource;
            }
          }
        });
      }
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
        this.$toast.show("Please fill required fields", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      }
    },
    prevTab() {
      this.currentTabIndex -= 1;
      if (this.currentTabIndex < this.lastTabIndex) {
        this.lastTab = false;
      }
      this.$nextTick(() => {
        let video = this.$refs.videoPreview;
        if (video) {
          if (this.videoSource) {
            video.src = this.videoSource;
          }
        }
      });
    },
    fetchLocations() {
      this.loading = true;
      const search = "";
      this.$store
        .dispatch("locations/getAll", { search })
        .then((response) => {
          this.locations = response.data.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    fetchSkills() {
      this.loading = true;
      const search = "";
      this.$store
        .dispatch("skills/getAll", { search })
        .then((response) => {
          this.skills = response.data.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    fetchQualifications() {
      this.loading = true;
      const search = "";
      this.$store
        .dispatch("qualification/getAll", { search })
        .then((response) => {
          this.qualifications = response.data.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    numberOnly(evt) {
      isNumber(evt);
    },
    introVideoSelected() {
      let video = document.getElementById("video-preview");
      this.previewIntroVideo = this.$refs.introVideo.files[0];
      if (validateFileFormat(this.previewIntroVideo, "video")) {
        this.$refs.introVideo.value = null;
        this.previewIntroVideo = null;
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          video.src = e.target.result;
        };
        reader.onloadend = () => {
          this.user.profile.introVideo = reader.result;
        };
        reader.readAsDataURL(this.previewIntroVideo);
      }
    },
    profilePicSelected() {
      this.profilePicture = this.$refs.profilePic.files[0];
      if (validateFileFormat(this.profilePicture, "image")) {
        this.$refs.profilePic.value = null;
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewProfilePic = e.target.result;
        };
        reader.onloadend = () => {
          this.user.profile.profilePic = reader.result;
        };
        reader.readAsDataURL(this.profilePicture);
      }
    },
    cnicFrontSelected() {
      this.cnicFront = this.$refs.cnicfront.files[0];
      if (validateFileFormat(this.cnicFront, "image")) {
        this.$refs.cnicfront.value = null;
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewCnicFront = e.target.result;
        };
        reader.onloadend = () => {
          this.user.profile.cnicFrontImage = reader.result;
        };
        reader.readAsDataURL(this.cnicFront);
      }
    },
    cnicBackSelected() {
      this.cnicBack = this.$refs.cnicback.files[0];
      if (validateFileFormat(this.cnicBack, "image")) {
        this.$refs.cnicback.value = null;
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewCnicBack = e.target.result;
        };
        reader.onloadend = () => {
          this.user.profile.cnicBackImage = reader.result;
        };
        reader.readAsDataURL(this.cnicBack);
      }
    },
    policeCertificateSelected() {
      this.policeCertificate = this.$refs.policeCertificate.files[0];
      if (validateFileFormat(this.policeCertificate, "image")) {
        this.$refs.policeCertificate.value = null;
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewPoliceCertificate = e.target.result;
        };
        reader.onloadend = () => {
          this.user.profile.policeCertificate = reader.result;
        };
        reader.readAsDataURL(this.policeCertificate);
      }
    },
    skillCertificateSelected() {
      this.skillCertificate = this.$refs.skillCertificate.files[0];
      if (validateFileFormat(this.skillCertificate, "image")) {
        this.$refs.skillCertificate.value = null;
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewSkillCertificate = e.target.result;
        };
        reader.onloadend = () => {
          this.user.profile.skillCertificate = reader.result;
        };
        reader.readAsDataURL(this.skillCertificate);
      }
    },
    addNewReference() {
      if (this.user.profile.references.length < 3) {
        this.user.profile.references.push(Object.assign({}, this.reference));
      } else {
        this.$toast.show("You can add max 3 references.", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 10000,
        });
      }
    },
    removeReference(index) {
      this.user.profile.references.splice(index, 1);
    },
    addNewExperience() {
      if (this.user.profile.experiences.length < 3) {
        this.user.profile.experiences.push(Object.assign({}, this.experience));
      } else {
        this.$toast.show("You can add max 3 experiences.", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 10000,
        });
      }
    },
    removeExperience(index) {
      this.user.profile.experiences.splice(index, 1);
    },
    fetchCategories() {
      this.loading = true;
      const search = "";
      this.$store
        .dispatch("businessCategories/getAll", { search })
        .then((response) => {
          this.categories = response.data.data;
          this.loading = false;
        });
    },
    fetch(id) {
      this.loading = true;
      const type = this.type;
      let videoSource = null;
      this.$store
        .dispatch("users/getSingle", { id, type })
        .then((response) => {
          this.user = response.data;
          if (this.user.profile) {
            this.user.profile.user_media.forEach((item) => {
              if (item.type == "intro_video") {
                this.videoSource = item.media.file_url;
              }
              if (item.type == "profile") {
                this.previewProfilePic = item.media.file_url;
              }
              if (item.type == "cnic_front") {
                this.previewCnicFront = item.media.file_url;
              }
              if (item.type == "cnic_back") {
                this.previewCnicBack = item.media.file_url;
              }
              if (item.type == "police_certificate") {
                this.previewPoliceCertificate = item.media.file_url;
              }
              if (item.type == "skill_certificate") {
                this.previewSkillCertificate = item.media.file_url;
              }
            });
          } else {
            this.user.profile = this.profile;
          }
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    submit() {
      const form = document.getElementById("create-form");
      if (!form.checkValidity()) {
        form.classList.add("was-validated");
        this.$toast.show("Please fill required fields", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        this.loading = true;
        if (this.user.profile.qualification) {
          this.user.profile.qualification_id =
            this.user.profile.qualification.id;
        }
        const data = toRaw(this.user);
        const id = this.userId;
        this.$store
          .dispatch("users/update", { data, id })
          .then((response) => {
            this.loading = false;
            if (response.success == true) {
              this.$toast.show("Successfuly updated.", {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 5000,
              });
              this.user.profile.cnicFrontImage = null;
              this.user.profile.cnicBackImage = null;
              this.user.profile.profilePic = null;
              this.user.profile.skillCertificate = null;
              this.user.profile.policeCertificate = null;
              this.user.profile.introVideo = null;
            } else {
              this.$toast.show(response.error.user, {
                theme: "outline",
                position: "top",
                type: "error",
                duration: 10000,
              });
              this.errors = response.error;
            }
          })
          .catch((e) => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
