import { createStore } from "vuex";

import layout from "./modules/layout";
import menu from "./modules/menu";
import auth from "./modules/auth";
import roles from "./modules/roles";
import admins from "./modules/admins";
import skills from "./modules/skills";
import locations from "./modules/locations";
import users from "./modules/users";
import companies from "./modules/companies";
import businessCategories from "./modules/businessCategories";
import jobCategories from "./modules/jobCategories";
import jobPosts from "./modules/jobPosts";
import jobApplications from "./modules/jobApplications";
import userProfiles from "./modules/userProfiles";
import jobHirings from "./modules/jobHirings";
import settings from "./modules/settings";
import feedback from "./modules/feedback";
import qualification from "./modules/qualification";
import cancellation from "./modules/cancellation";
import faq from "./modules/faq";
import notifications from "./modules/notifications";
import skillSet from "./modules/gig";
import website from "./modules/website";
import support from "./modules/support";
import form from "./modules/form";
import contact from "./modules/contact";
import dashboard from "./modules/dashboard";

export default createStore({
  state: { langIcon: "", langLangauge: "", isActive: false },
  getters: {
    langIcon: (state) => {
      return state.langIcon;
    },
    langLangauge: (state) => {
      return state.langLangauge;
    },
  },
  mutations: {
    changeLang(state, payload) {
      localStorage.setItem("currentLanguage", payload.id);
      localStorage.setItem("currentLanguageIcon", payload.icon);
      state.langIcon = payload.icon || "flag-icon-us";
      state.langLangauge = payload.id || "EN";
    },
    change(state) {
      state.isActive = !state.isActive;
    },
  },
  actions: {
    setLang({ commit }, payload) {
      commit("changeLang", payload);
    },
  },
  modules: {
    layout,
    menu,
    auth,
    roles,
    admins,
    skills,
    locations,
    users,
    companies,
    businessCategories,
    jobCategories,
    jobPosts,
    jobApplications,
    userProfiles,
    jobHirings,
    settings,
    feedback,
    qualification,
    cancellation,
    faq,
    notifications,
    skillSet,
    website,
    support,
    form,
    contact,
    dashboard,
  },
});
