<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mt-5 mx-auto">
        <router-link to="/jobPosts" class="btn btn-sm btn-primary mb-3"
          >Back</router-link
        >
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Edit Post</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit">
              <div class="form-group">
                <label>Employer <span class="text-danger">*</span></label>
                <multiselect
                  v-model="jobPost.employer_id"
                  :options="employers"
                  label="full_name"
                  required
                ></multiselect>
              </div>
              <div class="form-group mt-3">
                <label>Job Category <span class="text-danger">*</span></label>
                <multiselect
                  v-model="jobPost.job_category_id"
                  :options="categories"
                  label="name"
                  requried
                ></multiselect>
              </div>
              <div class="form-group mt-3">
                <label>Job Type <span class="text-danger">*</span></label>
                <select
                  class="select-form-control"
                  v-model="jobPost.type"
                  required
                >
                  <option value="">Select...</option>
                  <option value="Part Time">Part Time</option>
                  <option value="Full Day">Full Day</option>
                  <option value="Monthly">Monthly</option>
                </select>
              </div>
              <div class="form-group mt-3">
                <label>Job Title <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="jobPost.title"
                  required
                />
              </div>
              <div class="form-group mt-3">
                <label
                  >Job Description <span class="text-danger">*</span></label
                >
                <textarea
                  class="form-control"
                  v-model="jobPost.description"
                  required
                ></textarea>
              </div>
              <div class="form-group mt-3">
                <label>Experience <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="jobPost.experience"
                  @keydown="numberOnly($event)"
                  required
                />
              </div>
              <div class="form-group mt-3">
                <label>Salary <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="jobPost.salary"
                  @keydown="numberOnly($event)"
                  required
                />
              </div>
              <div class="form-group mt-3">
                <label>Salary Type <span class="text-danger">*</span></label>
                <select
                  class="select-form-control"
                  v-model="jobPost.salary_type"
                  required
                >
                  <option value="">Select...</option>
                  <option value="Negotiable">Negotiable</option>
                  <option value="Un-Negotiable">Un-Negotiable</option>
                </select>
              </div>
              <div class="form-group mt-3">
                <label>City/Area <span class="text-danger">*</span></label>
                <multiselect
                  v-model="jobPost.location_id"
                  :options="locations"
                  label="name"
                  group-values="children"
                  group-label="name"
                  requried
                ></multiselect>
              </div>
              <div class="form-group mt-3">
                <label>Address <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="jobPost.address"
                  required
                />
              </div>
              <div class="form-group mt-3">
                <label>Applications deadline</label>
                <input
                  type="date"
                  class="form-control"
                  v-model="jobPost.deadline"
                />
              </div>
              <div class="form-group mt-3">
                <label>Upload Pictures</label>
                <input
                  type="file"
                  ref="pictures"
                  multiple
                  class="form-control"
                  accept="image/png, image/jpg, image/jpeg"
                  @change="selectPictures"
                />
                <div class="image-group row">
                  <div
                    class="col-md-4 mt-3"
                    v-for="(picture, index) in picturesPreview"
                  >
                    <div style="position: relative">
                      <img
                        :src="picture"
                        class="img-thumbnail"
                        style="width: 100%"
                      />
                      <button
                        type="button"
                        class="btn btn-sm btn-danger"
                        style="position: absolute; top: 0; right: 0"
                        @click="removePicture(index)"
                      >
                        X
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group mt-3">
                <label>Upload Audio</label>
                <input
                  type="file"
                  ref="audio"
                  accept="audio/mp3"
                  class="form-control"
                  @change="selectAudio"
                />
                <audio
                  class="mt-3"
                  controls
                  id="audio"
                  v-show="audioPreview != null"
                ></audio>
              </div>
              <div class="row mt-3">
                <div class="col-md-9">
                  <button type="submit" class="btn btn-lg btn-primary w-100">
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <button
                    type="reset"
                    class="btn btn-lg btn-outline-danger w-100"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import { isNumber } from "@/helpers/helpers";

export default {
  data() {
    return {
      loading: false,
      employers: [],
      categories: [],
      jobPost: [],
      picturesPreview: [],
      audioPreview: null,
      audioFile: null,
      jobId: null,
      locations: [],
    };
  },
  created() {
    this.jobId = this.$route.params.id;
    this.fetch(this.jobId);
  },
  mounted() {
    this.fetchEmployer();
    this.fetchSkills();
    this.fetchLocations();
  },
  methods: {
    numberOnly(evt) {
      isNumber(evt);
    },
    fetch(id) {
      this.loading = true;
      this.$store.dispatch("jobPosts/getSingle", { id }).then((response) => {
        this.jobPost = response.data;
        this.jobPost.employer_id = this.jobPost.employer;
        this.jobPost.job_category_id = this.jobPost.category;
        this.jobPost.location_id = this.jobPost.location;
        this.jobPost.job_media.forEach((item) => {
          item.media.forEach((media) => {
            if (media.type == "Image") {
              this.picturesPreview.push(media.image_url);
            }
            if (media.type == "Audio") {
              let audio = document.getElementById("audio");
              audio.src = media.image_url;
              this.audioPreview = true;
            }
          });
        });
        this.loading = false;
      });
    },
    selectPictures() {
      this.jobPost.pictures = [];
      const countPictures =
        this.$refs.pictures.files.length + this.picturesPreview.length;
      if (countPictures <= 5) {
        for (var i = 0; i < this.$refs.pictures.files.length; i++) {
          let picture = this.$refs.pictures.files[i];
          let reader = new FileReader();
          reader.onload = (e) => {
            this.picturesPreview.push(e.target.result);
          };
          reader.onloadend = () => {
            this.jobPost.pictures.push(reader.result);
          };
          reader.readAsDataURL(picture);
        }
      } else {
        this.$toast.show("You can upload max 5 pictures.", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      }
      this.$refs.pictures.value = null;
    },
    removePicture(index) {
      this.picturesPreview.splice(index, 1);
      this.jobPost.pictures.splice(index, 1);
    },
    selectAudio() {
      this.audioPreview = this.$refs.audio.files[0];
      let audio = document.getElementById("audio");
      let reader = new FileReader();
      reader.onload = (e) => {
        audio.src = e.target.result;
      };
      reader.onloadend = () => {
        this.jobPost.audio = reader.result;
      };
      reader.readAsDataURL(this.audioPreview);
    },
    fetchEmployer() {
      this.loading = true;
      const type = "employer";
      this.$store.dispatch("userProfiles/getAll", { type }).then((response) => {
        this.employers = response.data;
        this.loading = false;
      });
    },
    fetchSkills() {
      this.loading = true;
      this.$store
        .dispatch("skills/getAllWithoutPagination")
        .then((response) => {
          this.categories = response.data;
          this.loading = false;
        });
    },
    fetchLocations() {
      this.loading = true;
      this.$store.dispatch("locations/getAllWithChildren").then((response) => {
        this.locations = response.data;
        this.loading = false;
      });
    },
    submit() {
      this.loading = true;
      this.jobPost.employer_id = this.jobPost.employer_id.id;
      this.jobPost.job_category_id = this.jobPost.job_category_id.id;
      this.jobPost.location_id = this.jobPost.location_id.id;
      const data = toRaw(this.jobPost);
      const id = this.jobId;
      this.$store
        .dispatch("jobPosts/update", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            this.$toast.show("Successfuly updated", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            this.jobPost.pictures = [];
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
  },
};
</script>
