<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mt-5 mx-auto">
        <router-link to="/notification" class="btn btn-sm btn-primary mb-3"
          >Back</router-link
        >
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Send Notification</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit">
              <div class="form-group">
                <label class="d-block"
                  >Send To <span class="text-danger">*</span></label
                >
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="admin"
                    value="admin"
                    name="send-to"
                    v-model="notification.sendTo"
                    required
                  />
                  <label class="form-check-label" for="admin">Admins</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="worker"
                    value="worker"
                    name="send-to"
                    v-model="notification.sendTo"
                    required
                  />
                  <label class="form-check-label" for="worker">Workers</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="employer"
                    value="employer"
                    name="send-to"
                    v-model="notification.sendTo"
                    required
                  />
                  <label class="form-check-label" for="employer"
                    >Employers</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="company"
                    value="company"
                    name="send-to"
                    v-model="notification.sendTo"
                    required
                  />
                  <label class="form-check-label" for="company"
                    >Companies</label
                  >
                </div>
              </div>

              <div class="form-group">
                <label>Title <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="notification.title"
                  required
                />
              </div>

              <div class="form-group mt-3">
                <label>Description <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="notification.body"
                  required
                />
              </div>

              <div class="row mt-3">
                <div class="col-md-9">
                  <button type="submit" class="btn btn-lg btn-primary w-100">
                    Send
                  </button>
                </div>
                <div class="col-md-3">
                  <button
                    type="reset"
                    class="btn btn-lg btn-outline-danger w-100"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      notification: {
        sendTo: null,
        title: null,
        body: null,
        uid: localStorage.getItem("uid"),
        channel: null,
        type: null,
      },
    };
  },
  methods: {
    submit() {
      this.loading = true;
      if (this.notification.sendTo == "admin") {
        this.notification.channel = "web";
        this.notification.type = "admin";
      } else {
        this.notification.channel = "mobile";
        this.notification.type = "user";
      }
      const data = this.notification;
      this.$store
        .dispatch("notifications/send", { data })
        .then((response) => {
          this.loading = false;
          Object.keys(this.notification).forEach(
            (key) => (this.notification[key] = null)
          );
          this.notification.uid = localStorage.getItem("uid");
          this.$toast.show("Notification sent", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>
