<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="card mt-5">
        <div class="card-body">
          <h3 class="text-center">App Users</h3>
          <div class="col-md-12 d-flex justify-content-end">
            <router-link
              v-if="permissions.includes('appusers_add')"
              to="/users/add"
              class="btn btn-sm btn-primary mb-2"
              >Add App User</router-link
            >
          </div>
          <div class="col-md-12">
            <ul class="nav nav-tabs border-tab" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="home-tab-pane"
                  aria-selected="true"
                  @click="fetch('worker')"
                >
                  Worker
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="profile-tab-pane"
                  aria-selected="false"
                  @click="fetch('employer')"
                >
                  Employer
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="contact-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#contact-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="contact-tab-pane"
                  aria-selected="false"
                  @click="fetch('company')"
                >
                  Company
                </button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="home-tab-pane"
                role="tabpanel"
                aria-labelledby="home-tab"
                tabindex="0"
              >
                <data-table
                  :columns="columns"
                  :items="items"
                  :module="module"
                  :type="worker"
                  :index="1"
                  :isEdit="isEdit"
                  :isBlock="isBlock"
                  :isDelete="isDelete"
                  :isApprove="isApprove"
                  :isSendNotification="isSendNotification"
                  @deleted="fetch('worker')"
                  @nextPage="nextPage"
                  @search="handleSearch"
                  @clear="fetch('worker')"
                ></data-table>
              </div>
              <div
                class="tab-pane fade"
                id="profile-tab-pane"
                role="tabpanel"
                aria-labelledby="profile-tab"
                tabindex="0"
              >
                <data-table
                  :columns="columns"
                  :items="items"
                  :module="module"
                  :type="employer"
                  :index="1"
                  :isEdit="isEdit"
                  :isBlock="isBlock"
                  :isDelete="isDelete"
                  :isApprove="isApprove"
                  :isSendNotification="isSendNotification"
                  @deleted="fetch('employer')"
                  @nextPage="nextPage"
                  @search="handleSearch"
                  @clear="fetch('employer')"
                ></data-table>
              </div>
              <div
                class="tab-pane fade"
                id="contact-tab-pane"
                role="tabpanel"
                aria-labelledby="contact-tab"
                tabindex="0"
              >
                <data-table
                  :columns="columns"
                  :items="items"
                  :module="module"
                  :type="company"
                  :index="1"
                  :isEdit="isEdit"
                  :isBlock="isBlock"
                  :isDelete="isDelete"
                  :isApprove="isApprove"
                  :isSendNotification="isSendNotification"
                  @deleted="fetch('company')"
                  @nextPage="nextPage"
                  @search="handleSearch"
                  @clear="fetch('compnay')"
                ></data-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    dataTable,
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          text: "CNIC",
          value: "cnic",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Phone",
          value: "phone",
        },
        {
          text: "Status",
          value: "status",
        },
      ],
      items: [],
      module: "users",
      worker: "Worker",
      employer: "Employer",
      company: "Company",
      permissions: [],
      isEdit: false,
      isBlock: false,
      isDelete: false,
      isApprove: false,
      isSendNotification: false,
      userType: null,
    };
  },
  created() {
    this.permissions = localStorage.getItem("permissions");
    if (this.permissions.includes("appusers_edit")) {
      this.isEdit = true;
    }
    if (this.permissions.includes("appusers_delete")) {
      this.isDelete = true;
    }
    if (this.permissions.includes("appusers_block")) {
      this.isBlock = true;
      this.isApprove = true;
    }
    if (this.permissions.includes("appusers_send_notification")) {
      this.isSendNotification = true;
    }
  },
  mounted() {
    this.fetch("worker");
  },
  methods: {
    nextPage(url) {
      this.loading = true;
      axiosInstance.get(url).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
    fetch(type = "", search = "") {
      this.userType = type;
      this.loading = true;
      this.$store
        .dispatch("users/getAll", { type, search })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    handleSearch(search) {
      this.fetch(this.userType, search);
    },
  },
};
</script>
