<template>
  <div>
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-4">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="Type here to search..."
            v-model="searchValue"
          />
          <span class="input-group-text" id="basic-addon2" @click="search"
            ><i class="fa fa-search"></i
          ></span>
          <span class="input-group-text" id="basic-addon2" @click="clearSearch"
            ><i class="icon-close"></i
          ></span>
        </div>
      </div>
    </div>
    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <th v-for="column in columns">{{ column.text }}</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in items.data" :key="i">
          <td v-for="column in columns">
            <template v-if="column.secondValue && item[column.value]">{{
              item[column.value][column.secondValue]
            }}</template>
            <template v-else>{{ item[column.value] }}</template>
          </td>
          <td style="width: 140px">
            <div class="d-flex align-items-center justify-content-center">
              <button
                v-if="isChangePassword"
                class="btn p-0 me-2 d-flex align-items-center justify-content-center"
                @click="changePassword(item)"
                title="Change Password"
              >
                <i class="fa fa-key text-primary" style="font-size: 20px"></i>
              </button>
              <button
                v-if="viewDetail"
                class="btn btn-outline-primary me-2 p-0"
                style="width: 100px"
                @click="hire(item)"
              >
                View Detail
              </button>
              <button
                v-if="isHireNow && item.status == 'Applied'"
                class="btn btn-outline-primary me-2 p-0"
                style="width: 100px"
                @click="hire(item)"
              >
                Hire Now
              </button>
              <button
                v-if="isEndJob || item.status == 'Hired'"
                class="btn btn-outline-primary me-2 p-0 w-100"
                @click="endJob(item)"
              >
                End
              </button>
              <button
                v-if="isEndJob || item.status == 'Hired'"
                class="btn btn-outline-primary me-2 p-0 w-100"
                data-bs-toggle="modal"
                :data-bs-target="'#cancelModal' + index"
                @click="selectItem(item)"
              >
                Cancel
              </button>
              <button
                v-if="item.status == 'Cancel'"
                class="btn btn-outline-primary me-2 p-0 w-100"
                data-bs-toggle="modal"
                :data-bs-target="'#reasonModal' + index"
                @click="selectItem(item)"
              >
                View Reason
              </button>
              <button
                v-if="isView"
                class="btn me-2 p-0 d-flex align-items-center justify-content-center"
                @click="openItem(item)"
                title="View Detail"
              >
                <i class="fa fa-eye text-primary" style="font-size: 22px"></i>
              </button>
              <button
                v-if="viewForm"
                class="btn me-2 p-0 d-flex align-items-center justify-content-center"
                @click="openItem(item)"
                title="View Submissions"
              >
                <i class="fa fa-eye text-primary" style="font-size: 22px"></i>
              </button>
              <button
                v-if="viewSubmission"
                class="btn me-2 p-0 d-flex align-items-center justify-content-center"
                @click="openItem(item)"
                title="View Submission"
              >
                <i class="fa fa-eye text-primary" style="font-size: 22px"></i>
              </button>
              <button
                v-if="isEdit"
                class="btn me-2 p-0 d-flex align-items-center justify-content-center"
                @click="openItem(item)"
                title="Edit"
              >
                <i class="fa fa-edit text-primary" style="font-size: 22px"></i>
              </button>
              <button
                v-if="isDelete"
                class="btn p-0 me-2 d-flex align-items-center justify-content-center"
                type="button"
                data-bs-toggle="modal"
                :data-bs-target="'#deleteModal' + index"
                @click="selectItem(item)"
                title="Delete"
              >
                <i
                  class="fa fa-trash-o text-danger"
                  style="font-size: 22px"
                ></i>
              </button>
              <button
                v-if="
                  isBlock &&
                  (item?.status == 'Active' || item?.status == 'Approved')
                "
                class="btn p-0 me-2 d-flex align-items-center justify-content-center"
                type="button"
                data-bs-toggle="modal"
                :data-bs-target="'#blockModal' + index"
                @click="selectItem(item)"
                title="Block"
              >
                <img
                  src="@/assets/svg/block.svg"
                  alt=""
                  width="20"
                  height="20"
                />
              </button>
              <button
                v-if="isApprove && item?.status == 'Blocked'"
                class="btn p-0 me-2 d-flex align-items-center justify-content-center"
                type="button"
                data-bs-toggle="modal"
                :data-bs-target="'#approveModal' + index"
                @click="selectItem(item)"
                title="Un-Block"
              >
                <img
                  src="@/assets/svg/approve.svg"
                  alt=""
                  width="20"
                  height="20"
                />
              </button>
              <button
                v-if="isSendNotification"
                class="btn p-0 me-2 d-flex align-items-center justify-content-center"
                type="button"
                data-bs-toggle="modal"
                :data-bs-target="'#notificationModal' + index"
                @click="selectItem(item)"
                title="Send Notification"
              >
                <i class="fa fa-send-o" style="font-size: 18px"></i>
              </button>
              <button
                v-if="sendForm"
                class="btn p-0 me-2 d-flex align-items-center justify-content-center"
                type="button"
                data-bs-toggle="modal"
                :data-bs-target="'#sendFormModal' + index"
                @click="selectItem(item)"
                title="Send Form"
              >
                <i class="fa fa-send-o" style="font-size: 18px"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <nav aria-label="Page navigation example">
      <ul class="pagination pagination-sm justify-content-end mt-3">
        <li class="page-item" v-for="page in items.links">
          <a
            href="javascript:void(0);"
            class="page-link"
            :class="{ active: page.active }"
            v-if="page.url != null"
            @click="movePages(page.url)"
            ><span v-html="page.label"></span
          ></a>
        </li>
      </ul>
    </nav>

    <!-- Delete Modal -->
    <div
      class="modal fade"
      :id="'deleteModal' + index"
      tabindex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <p>Are you sure you want to delete?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              data-bs-dismiss="modal"
            >
              No
            </button>
            <button
              type="button"
              class="btn btn-sm btn-primary"
              data-bs-dismiss="modal"
              @click="deleteItem"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Block Modal -->
    <div
      class="modal fade"
      :id="'blockModal' + index"
      tabindex="-1"
      aria-labelledby="blockModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <p>Are you sure you want to block?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              data-bs-dismiss="modal"
            >
              No
            </button>
            <button
              type="button"
              class="btn btn-sm btn-primary"
              data-bs-dismiss="modal"
              @click="blockUser"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Approve Modal -->
    <div
      class="modal fade"
      :id="'approveModal' + index"
      tabindex="-1"
      aria-labelledby="approveModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <p>Are you sure you want to un-block?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              data-bs-dismiss="modal"
            >
              No
            </button>
            <button
              type="button"
              class="btn btn-sm btn-primary"
              data-bs-dismiss="modal"
              @click="approveUser"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Notification Modal -->
    <div
      class="modal fade"
      :id="'notificationModal' + index"
      tabindex="-1"
      aria-labelledby="notificationModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <form @submit.prevent="sendNotification">
              <div class="form-group mb-2">
                <label>Notification Title</label>
                <input
                  type="text"
                  v-model="notification.title"
                  class="form-control"
                  required
                />
              </div>
              <div class="form-group mb-2">
                <label>Notification Detail</label>
                <input
                  type="text"
                  v-model="notification.body"
                  class="form-control"
                  required
                />
              </div>
              <button
                type="button"
                class="btn btn-sm btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-sm btn-primary">Send</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Cancel Modal -->
    <div
      class="modal fade"
      :id="'cancelModal' + index"
      tabindex="-1"
      aria-labelledby="cancelModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <p>Are you sure you want to cancel this job?</p>
            <p>Please select a reason to cancel.</p>
            <form @submit.prevent="cancelJob">
              <div class="form-check" v-for="reason in reasons">
                <input
                  class="form-check-input"
                  type="radio"
                  :name="'reason' + reason.id"
                  :id="'reason' + reason.id"
                  :value="reason.id"
                  v-model="reasonId"
                />
                <label class="form-check-label" :for="'reason' + reason.id">
                  {{ reason.name }}
                </label>
              </div>

              <button
                type="button"
                class="btn btn-sm btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="submit"
                class="btn btn-sm btn-primary"
                data-bs-dismiss="modal"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Reason Modal -->
    <div
      class="modal fade"
      :id="'reasonModal' + index"
      tabindex="-1"
      aria-labelledby="reasonModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <p v-if="selectedItem && selectedItem.reason">
              {{ selectedItem.reason.name }}
            </p>
            <p v-else>No reason given.</p>
            <button
              type="button"
              class="btn btn-sm btn-secondary me-2"
              data-bs-dismiss="modal"
              @click="selectedItem = null"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Send Form Modal -->
    <SendForm :index="index" :formId="selectedItem?.id"></SendForm>
  </div>
</template>
<script>
import router from "@/router";
import { toRaw } from "vue";
import SendForm from "./modals/sendForm.vue";

export default {
  components: {
    SendForm,
  },
  props: {
    columns: null,
    items: null,
    module: null,
    isChangePassword: false,
    type: null,
    index: 1,
    isEdit: true,
    isDelete: true,
    isBlock: false,
    isApprove: false,
    isSendNotification: false,
    isHireNow: false,
    isEndJob: false,
    viewDetail: false,
    isView: false,
    sendForm: false,
    viewForm: false,
    viewSubmission: false,
  },
  data() {
    return {
      searchValue: "",
      selectedItem: null,
      permissions: [],
      loading: false,
      reasons: [],
      reasonId: null,
      notification: {
        title: null,
        body: null,
        id: null,
        type: "user",
        channel: "web",
      },
    };
  },
  created() {
    this.permissions = localStorage.getItem("permissions");
  },
  mounted() {
    if (this.module == "jobApplications") {
      this.fetchCancellationReasons();
    }
  },
  methods: {
    sendNotification() {
      this.loading = true;
      this.notification.id = parseInt(this.selectedItem.id);
      const data = this.notification;
      this.$store
        .dispatch("notifications/send", { data })
        .then((response) => {
          this.$toast.show("Successfuly sent", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.loading = false;
          router.go();
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    cancelJob() {
      this.selectedItem.cancel_reason_id = this.reasonId;
      this.selectedItem.status = "Cancel";
      this.$emit("cancel", this.selectedItem);
    },
    fetchCancellationReasons() {
      this.$store
        .dispatch("cancellation/getAllWithoutPagination")
        .then((response) => {
          this.reasons = response.data;
        })
        .catch((e) => {
          console.log("cancellationReasons: ", e);
        });
    },
    search() {
      this.$emit("search", this.searchValue);
    },
    clearSearch() {
      this.searchValue = "";
      this.$emit("clear", this.searchValue);
    },
    hire(item) {
      this.$emit("hire", item);
    },
    endJob(item) {
      this.$emit("endJob", item);
    },
    changePassword(item) {
      router.push({
        path: `/${this.module}/changePassword/${item.encrypted_id}`,
      });
    },
    selectItem(item) {
      this.selectedItem = item;
    },
    movePages(url) {
      if (process.env.NODE_ENV && process.env.NODE_ENV == "production") {
        url = url.replace("http://", "https://");
      }
      this.$emit("nextPage", url);
    },
    openItem(item) {
      if (item.parent_id) {
        router.push({
          path: `/${this.module}/edit/${item.encrypted_id}/${item.parent_encrypted_id}`,
        });
      } else {
        if (this.module == "users") {
          router.push({
            path: `/${this.module}/edit/${item.encrypted_id}`,
            query: { type: this.type },
          });
        } else if (this.module == "form") {
          router.push({
            path: `/${this.module}/submission/${item.encrypted_id}`,
          });
        } else if (this.module == "submission") {
          router.push({
            path: `/form/detail/${item.encrypted_form_id}/${item.user_id}`,
          });
        } else {
          router.push({ path: `/${this.module}/edit/${item.encrypted_id}` });
        }
      }
    },
    deleteItem() {
      this.loading = true;
      const id = toRaw(this.selectedItem.id);
      this.$store
        .dispatch(`${this.module}/delete`, { id })
        .then(() => {
          this.loading = false;
          this.$toast.show("Successfuly deleted", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.$emit("deleted");
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    blockUser() {
      this.loading = true;
      const id = toRaw(this.selectedItem.id);
      const data = {
        status: 2,
      };
      this.$store
        .dispatch(`${this.module}/updateUserStatus`, { data, id })
        .then(() => {
          this.loading = false;
          this.$toast.show("Successfuly updated", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.$emit("deleted");
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    approveUser() {
      this.loading = true;
      const id = toRaw(this.selectedItem.id);
      const data = {
        status: 1,
      };
      this.$store
        .dispatch(`${this.module}/updateUserStatus`, { data, id })
        .then(() => {
          this.loading = false;
          this.$toast.show("Successfuly update", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.$emit("deleted");
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>
<style scoped>
.page-link.active {
  background-color: var(--bs-pagination-color) !important;
  color: #fff !important;
}
</style>
