export var menuItems = {
  data: [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: "stroke-home",
      iconf: "fill-home",
      type: "link",
      active: false,
      public: true,
    },
    {
      title: "Administration",
      icon: "stroke-user",
      iconf: "fill-user",
      type: "sub",
      active: false,
      module: ["admin_view", "role_view"],
      children: [
        {
          path: "/admins",
          title: "Admins",
          type: "link",
          active: false,
          module: "admin_view",
        },
        {
          path: "/roles",
          title: "Roles",
          type: "link",
          active: false,
          module: "role_view",
        },
      ],
    },
    {
      path: "/users",
      title: "App Users",
      icon: "stroke-user",
      iconf: "fill-user",
      type: "link",
      active: false,
      module: "appusers_view",
    },
    {
      title: "Job",
      icon: "stroke-job-search",
      iconf: "fill-job-search",
      type: "sub",
      active: false,
      module: ["job_post_view", "job_app_view"],
      children: [
        {
          path: "/jobPosts",
          title: "Job Posts",
          type: "link",
          active: false,
          module: "job_post_view",
        },
        {
          path: "/jobApplications",
          title: "Job Applications",
          type: "link",
          active: false,
          module: "job_app_view",
        },
      ],
    },
    {
      path: "/skillSet",
      title: "Skill-set",
      icon: "stroke-knowledgebase",
      iconf: "fill-knowledgebase",
      type: "link",
      active: false,
      module: "appusers_view",
    },
    {
      path: "/notification",
      title: "Notifications",
      icon: "notification",
      iconf: "notification",
      type: "link",
      active: false,
      module: "notification_view",
    },
    {
      path: "/feedbacks",
      title: "Feedback",
      icon: "stroke-knowledgebase",
      iconf: "fill-knowledgebase",
      type: "link",
      active: false,
      module: "feedback",
    },
    {
      path: "/suggestions",
      title: "Suggestions",
      icon: "stroke-knowledgebase",
      iconf: "fill-knowledgebase",
      type: "link",
      active: false,
      module: "feedback",
    },
    {
      path: "/contact-us",
      title: "Contact Us",
      icon: "stroke-knowledgebase",
      iconf: "fill-knowledgebase",
      type: "link",
      active: false,
      module: "contact_us",
    },
    {
      path: "/support",
      title: "Support",
      icon: "stroke-knowledgebase",
      iconf: "fill-knowledgebase",
      type: "link",
      active: false,
      module: "support_view",
    },
    {
      path: "/faq",
      title: "FAQs",
      icon: "stroke-knowledgebase",
      iconf: "fill-knowledgebase",
      type: "link",
      active: false,
      module: "faq_view",
    },
    // {
    //   path: "/advance-search",
    //   title: "Advance Sesarch",
    //   icon: "stroke-knowledgebase",
    //   iconf: "fill-knowledgebase",
    //   type: "link",
    //   active: false,
    //   module: "advance_search",
    // },
    {
      path: "/content",
      title: "Website Content",
      icon: "stroke-knowledgebase",
      iconf: "fill-knowledgebase",
      type: "link",
      active: false,
      module: "web_content",
    },
    {
      path: "/form",
      title: "Dynamic Forms",
      icon: "stroke-knowledgebase",
      iconf: "fill-knowledgebase",
      type: "link",
      active: false,
      module: "form_view",
    },
    {
      title: "Settings",
      icon: "stroke-editors",
      iconf: "fill-editors",
      type: "sub",
      active: false,
      module: ["settings", "skill_view", "locations_view", "bus_cat_view"],
      children: [
        {
          path: "/settings",
          title: "General Settings",
          type: "link",
          active: false,
          module: "settings",
        },
        {
          path: "/skills",
          title: "Skills",
          type: "link",
          active: false,
          module: "skill_view",
        },
        {
          path: "/locations",
          title: "Locations",
          type: "link",
          active: false,
          module: "locations_view",
        },
        {
          path: "/businessCategories",
          title: "Business Categories",
          type: "link",
          active: false,
          module: "bus_cat_view",
        },
        {
          path: "/qualification",
          title: "Qualification",
          type: "link",
          active: false,
          module: "qualification_view",
        },
        {
          path: "/cancellation",
          title: "Cancellation Reasons",
          type: "link",
          active: false,
          module: "cancellation_view",
        },
      ],
    },
  ],
};
