<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="card mt-5">
        <div class="card-body">
          <h3 class="text-center">Job Applications</h3>
          <div class="col-md-12 d-flex justify-content-end">
            <button
              v-if="permissions.includes('job_app_add')"
              class="btn btn-sm btn-primary mb-2 float-end"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
            >
              Apply to Job
            </button>
          </div>
          <div class="col-md-12">
            <data-table
              :columns="columns"
              :module="module"
              :items="items"
              :isEdit="isEdit"
              :isDelete="isDelete"
              :isHireNow="hireNow"
              :isEndJob="endJob"
              @deleted="fetch"
              @hire="hire"
              @endJob="jobEnd"
              @nextPage="nextPage"
              @cancel="cancel"
            ></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Add @applied="fetch"></Add>
</template>
<script>
import DataTable from "@/components/dataTable.vue";
import Add from "./add.vue";
import axiosInstance from "@/helpers/axios";
import { toRaw } from "vue";

export default {
  components: {
    DataTable,
    Add,
  },
  data() {
    return {
      items: [],
      columns: [
        {
          text: "Job Title",
          value: "job",
          secondValue: "title",
        },
        {
          text: "Worker",
          value: "worker",
          secondValue: "f_name",
        },
        {
          text: "Negotiation Rate",
          value: "rate",
        },
        {
          text: "Negotiation Comment",
          value: "comment",
        },
        {
          text: "Status",
          value: "status",
        },
      ],
      module: "jobApplications",
      loading: false,
      permissions: [],
      isEdit: false,
      isDelete: false,
      application: {
        worker_id: "",
        job_id: "",
        description: null,
        status: "Applied",
      },
      hireNow: true,
      endJob: false,
    };
  },
  created() {
    this.permissions = localStorage.getItem("permissions");
    this.fetch();
  },
  methods: {
    cancel(item) {
      this.loading = true;
      const id = item.id;
      const data = {
        cancel_reason_id: item.cancel_reason_id,
        status: item.status,
      };
      this.$store
        .dispatch("jobApplications/update", { id, data })
        .then(() => {
          this.loading = false;
          this.$toast.show("Job has been Canceled.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.fetch();
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    nextPage(url) {
      this.loading = true;
      axiosInstance.get(url).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
    jobEnd(item) {
      this.loading = true;
      const id = item.id;
      const data = {
        status: "Completed",
      };
      this.$store
        .dispatch("jobApplications/update", { id, data })
        .then(() => {
          this.loading = false;
          this.$toast.show("Job has been Ended.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.fetch();
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    hire(item) {
      this.loading = true;
      const id = item.id;
      const data = {
        status: "Hired",
      };
      this.$store
        .dispatch("jobApplications/update", { id, data })
        .then(() => {
          this.loading = false;
          this.$toast.show("Worker has been hired.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.fetch();
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    fetch() {
      this.loading = true;
      this.$store
        .dispatch("jobApplications/getAll")
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>
