<template>
  <div class="col-xxl-4 col-md-6 box-col-6 col-ed-6">
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-header card-no-border">
            <div class="header-top">
              <h5>Users</h5>
            </div>
          </div>
          <div class="card-body pt-0">
            <ul class="user-list">
              <li>
                <div class="user-icon primary">
                  <div class="user-box">
                    <vue-feather
                      class="font-primary"
                      type="user-plus"
                    ></vue-feather>
                  </div>
                </div>
                <div>
                  <h5 class="mb-1">{{ data.total_users }}</h5>
                  <span class="font-primary d-flex align-items-center"
                    ><span class="f-w-500">Total</span></span
                  >
                </div>
              </li>
              <li>
                <div class="user-icon primary">
                  <div class="user-box">
                    <vue-feather
                      class="font-primary"
                      type="user-plus"
                    ></vue-feather>
                  </div>
                </div>
                <div>
                  <h5 class="mb-1">{{ data.workers }}</h5>
                  <span class="font-primary d-flex align-items-center"
                    ><span class="f-w-500">Workers</span></span
                  >
                </div>
              </li>
            </ul>
            <ul class="user-list mt-3">
              <li>
                <div class="user-icon primary">
                  <div class="user-box">
                    <vue-feather
                      class="font-primary"
                      type="user-plus"
                    ></vue-feather>
                  </div>
                </div>
                <div>
                  <h5 class="mb-1">{{ data.employers }}</h5>
                  <span class="font-primary d-flex align-items-center"
                    ><span class="f-w-500">Employers</span></span
                  >
                </div>
              </li>
              <li>
                <div class="user-icon primary">
                  <div class="user-box">
                    <vue-feather
                      class="font-primary"
                      type="user-plus"
                    ></vue-feather>
                  </div>
                </div>
                <div>
                  <h5 class="mb-1">{{ data.companies }}</h5>
                  <span class="font-primary d-flex align-items-center"
                    ><span class="f-w-500">Companies</span></span
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-xl-12">
        <div class="card growth-wrap">
          <div class="card-header card-no-border">
            <div class="header-top">
              <h5>Registration Growth</h5>
              <DropDown3 />
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="growth-wrapper">
              <apexchart
                height="200"
                type="line"
                :options="apexDashboard.options6"
                :series="growthArray"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apexDashboard } from "../../../data/comon";

export default {
  props: {
    data: null,
  },
  data() {
    return {
      apexDashboard: apexDashboard,
      growthArray: [
        {
          name: "Growth",
          data: [],
        },
      ],
    };
  },
  mounted() {
    this.growthArray[0].data = Object.values(this.data?.users_by_month);
  },
};
</script>
