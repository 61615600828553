<template>
  <div class="col-xxl-4 col-md-6 box-col-6">
    <div class="card">
      <div class="card-header card-no-border">
        <div class="header-top">
          <h5 class="m-0">Jobs</h5>
        </div>
      </div>
      <div class="card-body pt-0">
        <div class="schedule-container">
          <apexchart
            height="355"
            type="bar"
            :options="apexDashboard.options5"
            :series="apexDashboard.series5"
          >
          </apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apexDashboard } from "../../../data/comon";

export default {
  props: {
    data: null,
  },
  data() {
    return {
      apexDashboard: apexDashboard,
    };
  },
  mounted() {
    apexDashboard.series5[0].data[0].x = "Total";
    apexDashboard.series5[0].data[1].x = "Posted";
    apexDashboard.series5[0].data[2].x = "In Progress";
    apexDashboard.series5[0].data[3].x = "Completed";
    apexDashboard.series5[0].data[0].y = [this.data.total_jobs];
    apexDashboard.series5[0].data[1].y = [this.data.posted_jobs];
    apexDashboard.series5[0].data[2].y = [this.data.in_progress_jobs];
    apexDashboard.series5[0].data[3].y = [this.data.done_jobs];
  },
};
</script>
