<template>
  <div class="header-wrapper row m-0">
    <Logo />
    <div
      class="nav-right col-xxl-7 col-xl-6 col-md-7 col-8 pull-right right-header p-0 ms-auto"
    >
      <ul class="nav-menus">
        <Notifications />
        <Profile />
      </ul>
    </div>
  </div>
</template>
<script>
import Profile from "./profile";
import Logo from "./logo.vue";
import Notifications from "./notifications.vue";

export default {
  components: {
    Profile,
    Logo,
    Notifications,
  },
};
</script>

<style scoped>
.toggle-sidebar svg {
  stroke: #7366ff !important;
}
</style>
