<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mt-5 mx-auto">
        <router-link
          to="/businessCategories"
          class="btn btn-sm btn-primary mb-3"
          >Back</router-link
        >
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Edit Category</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit">
              <label>Name</label>
              <input
                type="text"
                class="form-control mb-3"
                required
                v-model="category.name"
              />
              <div class="row mt-3">
                <div class="col-md-9">
                  <button type="submit" class="btn btn-lg btn-primary w-100">
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <button
                    type="reset"
                    class="btn btn-lg btn-outline-danger w-100"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";

export default {
  data() {
    return {
      category: {
        name: "",
      },
      categoryId: null,
      loading: false,
    };
  },
  mounted() {
    this.categoryId = this.$route.params.id;
    this.fetch(this.categoryId);
  },
  methods: {
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("businessCategories/getSingle", { id })
        .then((response) => {
          this.category = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.category);
      const id = this.categoryId;
      this.$store
        .dispatch("businessCategories/update", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.$toast.show("Successfuly updated", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            this.$refs.addRole.reset();
          } else {
            this.$toast.show("Failed to udpate", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
        });
    },
  },
};
</script>
