<template>
  <div class="container-fluid">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5">
        <router-link to="/users" class="btn btn-sm btn-primary mb-3"
          >Back</router-link
        >
        <form
          @submit.prevent="submit"
          class="needs-validation"
          novalidate
          id="create-form"
          autocomplete="off"
        >
          <input type="text" name="dummy_field" style="display: none" />
          <div class="card">
            <div class="card-body">
              <!-- Registraion form -->
              <div class="col-md-12" v-if="currentTabIndex == 1">
                <h4 class="text-center">Registration</h4>
                <div class="form-group">
                  <label class="d-block"
                    >User Type <span class="text-danger">*</span></label
                  >
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="worker"
                      value="1"
                      v-model="profile.type"
                      @change="changeType('worker')"
                    />
                    <label class="form-check-label" for="worker">Worker</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="employer"
                      value="2"
                      v-model="profile.type"
                      @change="changeType('employer')"
                    />
                    <label class="form-check-label" for="employer"
                      >Employer</label
                    >
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="company"
                      value="3"
                      v-model="profile.type"
                      @change="changeType('company')"
                    />
                    <label class="form-check-label" for="company"
                      >Company</label
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label class="d-block"
                    >Registration Type <span class="text-danger">*</span></label
                  >
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="new"
                      value="new"
                      v-model="regType"
                      @change="changeRegType"
                    />
                    <label class="form-check-label" for="new">New</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="existing"
                      value="existing"
                      v-model="regType"
                      @change="changeRegType"
                    />
                    <label class="form-check-label" for="existing"
                      >Existing</label
                    >
                  </div>
                </div>
                <div class="form-group" v-if="isUsers">
                  <label>Select User <span class="text-danger">*</span></label>
                  <span class="d-block text-danger" v-for="error in errors">{{
                    error.user
                  }}</span>
                  <multiselect
                    v-model="user.userId"
                    placeholder="Select User"
                    label="cnic"
                    :options="users"
                    :required="true"
                  ></multiselect>
                </div>
                <div class="form-group" v-if="isCompanies">
                  <label
                    >Select Company <span class="text-danger">*</span></label
                  >
                  <span class="d-block text-danger" v-for="error in errors">{{
                    error.user
                  }}</span>
                  <multiselect
                    v-model="profile.companyId"
                    placeholder="Select Company"
                    label="name"
                    :options="companies"
                    :required="true"
                  ></multiselect>
                </div>
                <div
                  class="form-group"
                  v-if="regType == 'new' || profile.type == 3"
                >
                  <div class="col-md-12 mt-3">
                    <label>CNIC <span class="text-danger">*</span></label>
                    <span class="d-block text-danger">{{ errors.user }}</span>
                    <input
                      type="text"
                      v-model="user.cnic"
                      class="form-control"
                      :required="isNewReg"
                      @keydown="numberOnly($event)"
                      minlength="13"
                      maxlength="13"
                      autocomplete="new-cnic"
                    />
                    <div class="invalid-feedback">
                      CNIC length must be 13 digits
                    </div>
                  </div>
                </div>
                <div
                  class="form-group"
                  v-if="regType == 'new' || profile.type == 3"
                >
                  <div class="col-md-12 mt-3">
                    <label>Password <span class="text-danger">*</span></label>
                    <input
                      type="password"
                      v-model="user.password"
                      class="form-control"
                      :required="isNewReg"
                      autocomplete="new-password"
                    />
                  </div>
                </div>
                <div
                  class="form-group"
                  v-if="regType == 'new' || profile.type == 3"
                >
                  <div class="col-md-12 mt-3">
                    <label
                      >Re-Type Password
                      <span class="text-danger">*</span></label
                    >
                    <span
                      class="text-danger d-block"
                      v-if="errors.confirmPassword"
                    >
                      {{ errors.confirmPassword }}
                    </span>
                    <input
                      type="password"
                      v-model="user.confirmPassword"
                      class="form-control"
                      :required="isNewReg"
                      autocomplete="new-password"
                    />
                  </div>
                </div>
              </div>

              <!-- Basic Informtaion -->
              <div class="col-md-12" v-if="currentTabIndex == 2">
                <h4 class="text-center">Basic Information</h4>
                <div
                  class="d-flex mt-3 justify-content-center align-items-center"
                >
                  <img
                    :src="previewProfilePic"
                    class="img-thumbnail"
                    style="width: 100px; height: 100px"
                  />
                  <div class="ms-3" style="width: 84%">
                    <label>Upload Profile Picture</label>
                    <small class="ms-2">(jpeg, jpg, png)</small>
                    <input
                      type="file"
                      ref="profilePic"
                      class="form-control"
                      accept="image/png, image/jpg, image/jpeg"
                      @change="profilePicSelected"
                    />
                  </div>
                </div>
                <div class="form-group row mt-3">
                  <div class="col-md-6">
                    <label>First Name <span class="text-danger">*</span></label>
                    <input
                      type="text"
                      class="form-control mb-3"
                      v-model="profile.fName"
                      required
                    />
                  </div>
                  <div class="col-md-6">
                    <label>Last Name</label>
                    <input
                      type="text"
                      class="form-control mb-3"
                      v-model="profile.lName"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12">
                    <label>Father Name</label>
                    <input
                      type="text"
                      v-model="profile.fatherName"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-6 mt-3">
                    <label>CNIC Front Image</label>
                    <small class="ms-2">(jpeg, jpg, png)</small>
                    <input
                      type="file"
                      class="form-control"
                      ref="cnicfront"
                      accept="image/png, image/jpg, image/jpeg"
                      @change="cnicFrontSelected"
                    />
                    <img
                      v-if="previewCnicFront"
                      :src="previewCnicFront"
                      class="img-thumbnail mt-3"
                    />
                  </div>
                  <div class="col-md-6 mt-3">
                    <label>CNIC Back Image</label>
                    <small class="ms-2">(jpeg, jpg, png)</small>
                    <input
                      type="file"
                      class="form-control"
                      ref="cnicback"
                      accept="image/png, image/jpg, image/jpeg"
                      @change="cnicBackSelected"
                    />
                    <img
                      v-if="previewCnicBack"
                      :src="previewCnicBack"
                      class="img-thumbnail mt-3"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-6 mt-3">
                    <label>Date of Birth</label>
                    <input
                      type="date"
                      v-model="profile.dob"
                      class="form-control"
                    />
                  </div>
                  <div class="col-md-6 mt-3">
                    <label>Age</label>
                    <input
                      type="text"
                      v-model="profile.age"
                      class="form-control"
                      @keydown="numberOnly($event)"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label>Phone <span class="text-danger">*</span></label>
                    <input
                      type="text"
                      v-model="user.phone"
                      maxlength="11"
                      class="form-control"
                      @keydown="numberOnly($event)"
                      required
                    />
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label>Email <span class="text-danger">*</span></label>
                    <input
                      type="email"
                      v-model="user.email"
                      class="form-control"
                      pattern="[^\s@]+@[^\s@]+\.[^\spatt@]+"
                      required
                    />
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label>Gender <span class="text-danger">*</span></label>
                    <div class="col-md-12">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="male"
                          value="Male"
                          v-model="profile.gender"
                          requried
                        />
                        <label class="form-check-label" for="male">Male</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="female"
                          value="Female"
                          v-model="profile.gender"
                          requried
                        />
                        <label class="form-check-label" for="female"
                          >Female</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="other"
                          value="Other"
                          v-model="profile.gender"
                          requried
                        />
                        <label class="form-check-label" for="other"
                          >Others</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label>Address</label>
                    <input
                      type="text"
                      v-model="profile.address"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="form-group mt-3" v-if="profile.type == 1">
                  <label>Locations where you want to work</label>
                  <multiselect
                    v-model="profile.locations"
                    placeholder="Select Locations"
                    label="name"
                    :multiple="true"
                    :max="3"
                    :options="locations"
                    :taggable="true"
                    :close-on-select="false"
                  ></multiselect>
                </div>
                <div class="form-group mt-3" v-if="profile.type == 1">
                  <label>Skills/Profession</label>
                  <multiselect
                    v-model="profile.skills"
                    placeholder="Select Skills"
                    label="name"
                    :multiple="true"
                    :max="3"
                    :options="skills"
                    :taggable="true"
                    :close-on-select="false"
                  ></multiselect>
                </div>
                <div class="form-group mt-3">
                  <label>Qualification</label>
                  <multiselect
                    v-model="profile.qualificationId"
                    placeholder="Select Qualification"
                    label="name"
                    :options="qualifications"
                    :taggable="true"
                  ></multiselect>
                </div>
                <div class="form-group" v-if="profile.type == 1">
                  <div class="col-md-12 mt-3">
                    <label>Self Introductory Video</label>
                    <small class="ms-2">(mp4)</small>
                    <input
                      type="file"
                      class="form-control"
                      ref="introVideo"
                      accept="video/mp4"
                      @change="introVideoSelected"
                    />
                    <video
                      class="mt-3 w-50"
                      id="video-preview"
                      controls
                      v-show="previewIntroVideo"
                    ></video>
                  </div>
                </div>
                <div class="form-group" v-if="profile.type == 1">
                  <div class="col-md-12 mt-3">
                    <label>Charactor Certificate by Police</label>
                    <small class="ms-2">(jpeg, jpg, png)</small>
                    <input
                      type="file"
                      class="form-control"
                      ref="policeCertificate"
                      accept="image/png, image/jpg, image/jpeg"
                      @change="policeCertificateSelected"
                    />
                    <img
                      v-if="previewPoliceCertificate"
                      :src="previewPoliceCertificate"
                      class="img-thumbnail mt-3"
                      style="width: 50%"
                    />
                  </div>
                </div>
                <div class="form-group" v-if="profile.type == 1">
                  <div class="col-md-12 mt-3">
                    <label>Skill Certificate</label>
                    <small class="ms-2">(jpeg, jpg, png)</small>
                    <input
                      type="file"
                      class="form-control"
                      ref="skillCertificate"
                      accept="image/png, image/jpg, image/jpeg"
                      @change="skillCertificateSelected"
                    />
                    <img
                      v-if="previewSkillCertificate"
                      :src="previewSkillCertificate"
                      class="img-thumbnail mt-3"
                      style="width: 50%"
                    />
                  </div>
                </div>
              </div>

              <!-- Experiences -->
              <template v-if="!isEmployer">
                <div class="col-md-12" v-if="currentTabIndex == expTab">
                  <h4 class="text-center">Experiences</h4>
                  <div class="form-group mb-3">
                    <label>Total Experience</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="profile.experience"
                      @keydown="numberOnly($event)"
                    />
                  </div>
                  <div class="col-md-12">
                    <div class="d-flex justify-content-end">
                      <button
                        type="button"
                        class="btn btn-sm btn-primary"
                        @click="addNewExperience"
                      >
                        Add
                      </button>
                    </div>
                    <div
                      v-for="(experience, index) in experiences"
                      class="mt-2"
                    >
                      <div class="d-flex justify-content-between">
                        <h6>Experience {{ index + 1 }}</h6>
                        <button
                          type="button"
                          class="btn btn-sm btn-outline-danger"
                          @click="removeExperience(index)"
                        >
                          Remove
                        </button>
                      </div>
                      <div class="form-group row">
                        <div class="col-md-6">
                          <label>Job Category</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="experience.jobCategory"
                          />
                        </div>
                        <div class="col-md-6">
                          <label>Company Name</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="experience.companyName"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="col-md-12 mt-3">
                          <label>Email</label>
                          <input
                            type="email"
                            v-model="experience.email"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="form-group row mt-3">
                        <div class="col-md-6">
                          <label>Start Date</label>
                          <input
                            type="date"
                            v-model="experience.startDate"
                            class="form-control"
                          />
                        </div>
                        <div class="col-md-6">
                          <label>End Date</label>
                          <input
                            type="date"
                            v-model="experience.endDate"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="col-md-12 mt-3">
                          <label>Address</label>
                          <input
                            type="text"
                            v-model="experience.address"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="col-md-12 mt-3">
                          <label>Job Type</label>
                          <input
                            type="text"
                            v-model="experience.jobType"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template v-if="!isCompany">
                <!-- References -->
                <div class="col-md-12" v-if="currentTabIndex == refTab">
                  <h4 class="text-center">References</h4>
                  <div class="col-md-12">
                    <div class="d-flex justify-content-end">
                      <button
                        type="button"
                        class="btn btn-sm btn-primary"
                        @click="addNewReference"
                      >
                        Add
                      </button>
                    </div>
                    <div v-for="(reference, index) in references" class="mt-2">
                      <div class="d-flex justify-content-between">
                        <h6>Reference {{ index + 1 }}</h6>
                        <button
                          type="button"
                          class="btn btn-sm btn-outline-danger"
                          @click="removeReference(index)"
                        >
                          Remove
                        </button>
                      </div>
                      <div class="form-group">
                        <div class="col-md-12">
                          <label>Full Name</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="reference.fName"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="col-md-12 mt-3">
                          <label>Phone</label>
                          <input
                            type="text"
                            v-model="reference.phone"
                            maxlength="11"
                            class="form-control"
                            @keydown="numberOnly($event)"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="col-md-12 mt-3">
                          <label>Relation</label>
                          <input
                            type="text"
                            v-model="reference.relation"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="col-md-12 mt-3">
                          <label>Designation</label>
                          <input
                            type="text"
                            v-model="reference.designation"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <!-- Company Information -->
              <div class="col-md-12" v-if="currentTabIndex == comTab">
                <h4 class="text-center">Company Information</h4>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label
                      >Company Name <span class="text-danger">*</span></label
                    >
                    <span class="d-block text-danger" v-for="error in errors">{{
                      error.name
                    }}</span>
                    <input
                      type="text"
                      v-model="company.name"
                      class="form-control"
                      :required="comReqFields.name"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label>Company Phone</label>
                    <input
                      type="text"
                      v-model="company.phone"
                      maxlength="11"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label>Official Website</label>
                    <input
                      type="text"
                      v-model="company.website"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label
                      >Registration No.
                      <span class="text-danger">*</span></label
                    >
                    <span class="d-block text-danger" v-for="error in errors">{{
                      error.reg
                    }}</span>
                    <input
                      type="text"
                      v-model="company.regNo"
                      class="form-control"
                      :required="comReqFields.regNo"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label>Business Category</label>
                    <select
                      v-model="company.categoryId"
                      class="select-form-control"
                    >
                      <option value="">Select...</option>
                      <option
                        v-for="category in categories"
                        :value="category.id"
                      >
                        {{ category.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            v-if="!lastTab"
            type="button"
            class="btn btn-primary float-end mb-3"
            @click="nextTab"
          >
            Next
          </button>
          <button
            v-if="lastTab"
            type="submit"
            class="btn btn-primary float-end mb-3"
          >
            Done
          </button>
          <button
            type="button"
            class="btn btn-primary float-start mb-3"
            @click="prevTab"
            v-if="currentTabIndex > 1"
          >
            Previous
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import "form-wizard-vue3/dist/form-wizard-vue3.css";
import Wizard from "form-wizard-vue3";
import { toRaw } from "vue";
import { isNumber, validateFileFormat } from "@/helpers/helpers";
import router from "@/router";

export default {
  components: {
    Wizard,
  },
  data() {
    return {
      currentTabIndex: 1,
      loading: false,
      user: {
        password: null,
        confirmPassword: null,
        is_worker: 0,
        is_employer: 0,
        userId: null,
        cnic: null,
        phone: null,
        email: null,
      },
      profile: {
        fName: null,
        lName: null,
        fatherName: null,
        cnicFrontImage: null,
        cnicBackImage: null,
        gender: null,
        dob: null,
        age: null,
        address: null,
        type: 1,
        companyId: null,
        qualificationId: null,
        locations: [],
        skills: [],
        introVideo: null,
        policeCertificate: null,
        skillCertificate: null,
        profilePic: null,
        experience: null,
      },
      company: {
        name: null,
        phone: null,
        website: null,
        regNo: null,
        categoryId: "",
      },
      errors: [],
      isCompany: false,
      isEmployer: false,
      comReqFields: {
        name: false,
        regNo: false,
      },
      references: [],
      reference: {
        fName: null,
        lName: null,
        phone: null,
        relation: null,
        designation: null,
      },
      experiences: [],
      experience: {
        jobCategory: null,
        companyName: null,
        email: null,
        startDate: null,
        endDate: null,
        address: null,
        jobType: null,
      },
      regType: "new",
      isNewReg: true,
      users: [],
      companies: [],
      isUsers: false,
      isCompanies: false,
      profilePicture: null,
      previewProfilePic: null,
      cnicFront: null,
      previewCnicFront: null,
      cnicBack: null,
      previewCnicBack: null,
      introVideo: null,
      previewIntroVideo: null,
      policeCertificate: null,
      previewPoliceCertificate: null,
      skillCertificate: null,
      previewSkillCertificate: null,
      categories: [],
      locations: [],
      skills: [],
      qualifications: [],
      expTab: 3,
      refTab: 4,
      comTab: 5,
      lastTab: false,
      lastTabIndex: 4,
    };
  },
  mounted() {
    this.fetchLocations();
    this.fetchSkills();
    this.fetchQualifications();
    this.addNewReference();
    this.addNewExperience();
  },
  methods: {
    nextTab() {
      const form = document.getElementById("create-form");
      if (form.checkValidity() === true) {
        if (this.user.password != this.user.confirmPassword) {
          this.errors.confirmPassword = "Password didn't match";
        } else {
          this.currentTabIndex += 1;
          if (this.currentTabIndex == this.lastTabIndex) {
            this.lastTab = true;
          }
        }
      }
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
        this.$toast.show("Please fill required fields", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      }
    },
    prevTab() {
      this.currentTabIndex -= 1;
      if (this.currentTabIndex < this.lastTabIndex) {
        this.lastTab = false;
      }
    },
    fetchLocations() {
      this.loading = true;
      const search = "";
      this.$store
        .dispatch("locations/getAll", { search })
        .then((response) => {
          this.locations = response.data.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    fetchSkills() {
      this.loading = true;
      const search = "";
      this.$store
        .dispatch("skills/getAll", { search })
        .then((response) => {
          this.skills = response.data.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    fetchQualifications() {
      this.loading = true;
      const search = "";
      this.$store
        .dispatch("qualification/getAll", { search })
        .then((response) => {
          this.qualifications = response.data.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    numberOnly(evt) {
      isNumber(evt);
    },
    introVideoSelected() {
      let video = document.getElementById("video-preview");
      this.previewIntroVideo = this.$refs.introVideo.files[0];
      if (validateFileFormat(this.previewIntroVideo, "video")) {
        this.$refs.introVideo.value = null;
        this.previewIntroVideo = null;
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          video.src = e.target.result;
        };
        reader.onloadend = () => {
          this.profile.introVideo = reader.result;
        };
        reader.readAsDataURL(this.previewIntroVideo);
      }
    },
    profilePicSelected() {
      this.profilePicture = this.$refs.profilePic.files[0];
      if (validateFileFormat(this.profilePicture, "image")) {
        this.$refs.profilePic.value = null;
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewProfilePic = e.target.result;
        };
        reader.onloadend = () => {
          this.profile.profilePic = reader.result;
        };
        reader.readAsDataURL(this.profilePicture);
      }
    },
    cnicFrontSelected() {
      this.cnicFront = this.$refs.cnicfront.files[0];
      if (validateFileFormat(this.cnicFront, "image")) {
        this.$refs.cnicfront.value = null;
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewCnicFront = e.target.result;
        };
        reader.onloadend = () => {
          this.profile.cnicFrontImage = reader.result;
        };
        reader.readAsDataURL(this.cnicFront);
      }
    },
    cnicBackSelected() {
      this.cnicBack = this.$refs.cnicback.files[0];
      if (validateFileFormat(this.cnicBack, "image")) {
        this.$refs.cnicback.value = null;
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewCnicBack = e.target.result;
        };
        reader.onloadend = () => {
          this.profile.cnicBackImage = reader.result;
        };
        reader.readAsDataURL(this.cnicBack);
      }
    },
    policeCertificateSelected() {
      this.policeCertificate = this.$refs.policeCertificate.files[0];
      if (validateFileFormat(this.policeCertificate, "image")) {
        this.$refs.policeCertificate.value = null;
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewPoliceCertificate = e.target.result;
        };
        reader.onloadend = () => {
          this.profile.policeCertificate = reader.result;
        };
        reader.readAsDataURL(this.policeCertificate);
      }
    },
    skillCertificateSelected() {
      this.skillCertificate = this.$refs.skillCertificate.files[0];
      if (validateFileFormat(this.skillCertificate, "image")) {
        this.$refs.skillCertificate.value = null;
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewSkillCertificate = e.target.result;
        };
        reader.onloadend = () => {
          this.profile.skillCertificate = reader.result;
        };
        reader.readAsDataURL(this.skillCertificate);
      }
    },
    changeRegType() {
      if (this.regType == "new") {
        this.isNewReg = true;
        this.isUsers = false;
        this.isCompany = false;
        if (this.profile.type == 3) {
          this.isCompanies = false;
          this.isCompany = true;
          this.fetchCategories();
        }
      } else if (this.regType == "existing") {
        this.isNewReg = false;
        if (this.profile.type == 3) {
          this.lastTabIndex = 2;
          this.isUsers = false;
          this.isCompanies = true;
          this.isCompany = true;
          this.fetchCompanies();
        } else if (this.profile.type == 1) {
          this.isUsers = true;
          this.isCompanies = false;
          this.fetchUsers("employer");
        } else if (this.profile.type == 2) {
          this.isUsers = true;
          this.isCompanies = false;
          this.fetchUsers("worker");
        }
      }
    },
    addNewReference() {
      if (this.references.length < 3) {
        this.references.push(Object.assign({}, this.reference));
      } else {
        this.$toast.show("You can add max 3 references.", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 10000,
        });
      }
    },
    removeReference(index) {
      this.references.splice(index, 1);
    },
    addNewExperience() {
      if (this.experiences.length < 3) {
        this.experiences.push(Object.assign({}, this.experience));
      } else {
        this.$toast.show("You can add max 3 experiences.", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 10000,
        });
      }
    },
    removeExperience(index) {
      this.experiences.splice(index, 1);
    },
    fetchCategories() {
      this.loading = true;
      const search = "";
      this.$store
        .dispatch("businessCategories/getAll", { search })
        .then((response) => {
          this.categories = response.data.data;
          this.loading = false;
        });
    },
    fetchUsers(type) {
      this.loading = true;
      const search = "";
      this.$store
        .dispatch("users/getAll", { type, search })
        .then((response) => {
          this.users = response.data.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    fetchCompanies() {
      this.loading = true;
      this.$store
        .dispatch("companies/getAll")
        .then((response) => {
          this.companies = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    submit() {
      this.loading = true;
      if (this.user.password != this.user.confirmPassword) {
        this.errors.confirmPassword = "Password didn't match";
        this.loading = false;
      }
      if (this.profile.type == 1) {
        this.user.is_worker = 1;
      }
      if (this.profile.type == 2) {
        this.user.is_employer = 1;
      }
      this.user.profile = this.profile;
      if (this.profile.type == 3 && this.regType == "new") {
        this.user.company = this.company;
      }
      if (this.profile.companyId) {
        this.profile.companyId = this.profile.companyId.id;
      }
      if (this.references.length) {
        this.user.references = this.references;
      }
      if (this.experiences.length) {
        this.user.experiences = this.experiences;
      }
      if (!this.uploadProfilePic) {
        this.user.profile.file = this.uploadProfilePic;
      }
      this.user.regType = this.regType;
      if (this.profile.qualificationId) {
        this.profile.qualificationId = this.profile.qualificationId.id;
      }
      const data = toRaw(this.user);
      this.$store
        .dispatch("users/store", { data })
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            this.$toast.show("Successfuly added.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            router.go(0);
          } else {
            this.$toast.show(response.error.user, {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 10000,
            });
            this.errors = response.error;
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    changeType(type = null) {
      if (this.profile.type == 1) {
        this.expTab = 3;
        this.refTab = 4;
        this.comTab = 5;
        this.lastTabIndex = 4;
        this.isCompany = false;
        this.isEmployer = false;
        this.comReqFields.name = false;
        this.comReqFields.regNo = false;
        if (this.regType == "existing") {
          this.fetchUsers(type);
          this.isUsers = true;
          this.isCompanies = false;
        }
      }
      if (this.profile.type == 2) {
        this.isCompany = false;
        this.isEmployer = true;
        this.refTab = 3;
        this.comTab = 5;
        this.lastTabIndex = 3;
        this.comReqFields.name = false;
        this.comReqFields.regNo = false;
        if (this.regType == "existing") {
          this.fetchUsers(type);
          this.isUsers = true;
          this.isCompanies = false;
        }
      }
      if (this.profile.type == 3) {
        this.refTab = 4;
        this.expTab = 5;
        this.comTab = 3;
        this.lastTabIndex = 3;
        this.isCompany = true;
        if (this.regType == "new") {
          this.comReqFields.name = true;
          this.comReqFields.regNo = true;
          this.fetchCategories();
        }
        if (this.regType == "existing") {
          this.lastTabIndex = 2;
          this.comReqFields.name = false;
          this.comReqFields.regNo = false;
          this.isCompanies = true;
          this.isUsers = false;
          this.fetchCompanies();
        }
      }
    },
  },
};
</script>
