<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mx-auto mt-5">
        <router-link to="/cancellation" class="btn btn-sm btn-primary mb-3"
          >Back</router-link
        >
        <div class="card">
          <div class="card-body">
            <h3 class="text-center">Edit Reason</h3>
            <form @submit.prevent="submit">
              <div class="form-group row">
                <div class="col-md-12">
                  <label>Name <span class="text-danger">*</span></label>
                  <input
                    type="text"
                    class="form-control mb-3"
                    required
                    v-model="cancellation.name"
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-9">
                  <button type="submit" class="btn btn-lg btn-primary w-100">
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <button
                    type="reset"
                    class="btn btn-lg btn-outline-danger w-100"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      id: null,
      cancellation: [],
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.fetch(this.id);
  },
  methods: {
    submit() {
      this.loading = true;
      this.errors = [];
      const data = toRaw(this.cancellation);
      this.$store
        .dispatch("cancellation/update", { data })
        .then(() => {
          this.loading = false;
          this.$toast.show("Successfuly updated.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.cancellation.name = null;
          this.errors = [];
        })
        .catch((e) => {
          this.loading = false;
          this.errors = e.error;
        });
    },
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("cancellation/getSingle", { id })
        .then((response) => {
          this.cancellation = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>
