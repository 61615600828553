<template>
  <div class="row">
    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <h4>Job Details</h4>
              <section>
                <p class="m-0"><strong>Title: &nbsp;</strong>Home Carpenter</p>
                <p><strong>Category: &nbsp;</strong>Carpenter</p>
                <strong>Description</strong>
                <p>
                  Seeking a skilled Home Carpenter to enhance your living space?
                  Look no further! Our Home Carpenter services offer expertise
                  in custom woodworking, furniture assembly, and structural
                  repairs tailored to your home needs. From crafting bespoke
                  shelves and cabinets to repairing door frames and installing
                  flooring, our Home Carpenters bring precision, creativity, and
                  attention to detail to every project. Whether you're
                  envisioning a cozy reading nook or revamping your kitchen, our
                  team is dedicated to bringing your home improvement dreams to
                  life. Let us transform your space into a personalized
                  sanctuary with our expert carpentry services.
                </p>
                <p><strong>Salary: &nbsp;</strong>1000/PKR Per Day</p> <br>
                <p><strong>Address: &nbsp;</strong>Lahore Cantt</p><br>
                <audio controls></audio>
                <div class="row">
                  <div class="col-md-4">
                    <img src="#" alt="" class="img-responsive" />
                  </div>
                  <div class="col-md-4">
                    <img src="#" alt="" class="img-responsive" />
                  </div>
                  <div class="col-md-4">
                    <img src="#" alt="" class="img-responsive" />
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h4>Workers Applications</h4>
              <data-table
                :columns="columns"
                :module="module"
                :items="items"
                :viewDetail="viewDetail"
              ></data-table>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <h4>Feedbacks</h4>
              <div class="border p-2">
                <strong>Taha Ahmad</strong> <br>
                <p class="m-0"><strong>Posted At: &nbsp;</strong> 06-03-2024</p>
                <p>The Home Carpenter displayed exceptional craftsmanship in transforming our living space. From custom shelving to intricate furniture pieces, every detail was meticulously crafted, adding charm and functionality to our home.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataTable from "@/components/dataTable.vue";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      viewDetail: true,
      workers: [],
      items: [
        {
          name: "Taha Ahmad",
          skill: "Carpenter",
          location: "Lahore Cantt",
        },
        {
          name: "Taha Ahmad",
          skill: "Carpenter",
          location: "Lahore Cantt",
        },
        {
          name: "Taha Ahmad",
          skill: "Carpenter",
          location: "Lahore Cantt",
        },
        {
          name: "Taha Ahmad",
          skill: "Carpenter",
          location: "Lahore Cantt",
        },
      ],
      columns: [
        {
          text: "Full Name",
          value: "name",
        },
        {
          text: "Skill",
          value: "skill",
        },
        {
          text: "Location",
          value: "location",
        },
      ],
    };
  },
};
</script>
