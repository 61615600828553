<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-10 mt-5 mx-auto">
        <button @click="$router.go(-1)" class="btn btn-sm btn-primary mb-3">
          Back
        </button>
        <div class="card">
          <div class="card-body">
            <h3 class="text-center">Form Detail</h3>
            <div class="form-group mb-3" v-for="item in form">
              <label for="">{{ item.question.title }}</label>
              <input
                v-if="item.question.type == 'text'"
                type="text"
                v-model="item.answer"
                class="form-control"
                disabled
              />
              <template v-if="item.question.type == 'checkbox'">
                <label
                  class="login-form-check d-block"
                  v-for="option in item.question.options"
                >
                  <input
                    class="login-form-check-input me-3"
                    type="checkbox"
                    id="flexCheckDefault"
                    :name="item.question_id"
                    :value="option.id"
                    v-model="item.answer"
                    :true-value="option.id"
                    disabled
                  />
                  <span class="login-check-span">{{ option.title }}</span>
                </label>
              </template>
              <template v-if="item.question.type == 'radio'">
                <label
                  class="login-form-radio position-relative d-block"
                  v-for="option in item.question.options"
                >
                  <input
                    class="login-form-radio-input me-3"
                    type="radio"
                    id="flexCheckDefault"
                    :name="item.question_id"
                    :value="option.id"
                    v-model="item.answer"
                    disabled
                  />
                  <span class="login-radio-span position-relative">{{
                    option.title
                  }}</span>
                </label>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      form: null,
      formId: null,
      userId: null,
      checkbox: [12, 13],
    };
  },
  mounted() {
    this.formId = this.$route.params.formId;
    this.userId = this.$route.params.id;
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      const formId = this.formId;
      const userId = this.userId;
      this.$store
        .dispatch("form/formSingleSubmission", { formId, userId })
        .then((response) => {
          this.loading = false;
          this.form = response.data;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
  },
};
</script>
