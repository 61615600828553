import { createWebHistory, createRouter } from "vue-router";
import Body from "../components/body";
import Dashboard from "@/views/dashboard/default";
import Login from "@/auth/login";
import VerfiyEmail from "@/auth/verify-email.vue";
import ResetPassword from "@/auth/reset_password.vue";
import RoleIndex from "@/views/role/index";
import AddRole from "@/views/role/add";
import EditRole from "@/views/role/edit";
import AdminIndex from "@/views/admin/index";
import AddAdmin from "@/views/admin/add";
import EditAdmin from "@/views/admin/edit";
import AdminProfile from "@/views/admin/profile";
import AdminChangePassword from "@/views/admin/changePassword";
import SkillIndex from "@/views/skill/index";
import EditSkill from "@/views/skill/edit";
import LocationIndex from "@/views/location/index";
import AddLocation from "@/views/location/add";
import EditLocation from "@/views/location/edit";
import AppUserIndex from "@/views/appUser/index";
import AddAppUser from "@/views/appUser/add";
import EditAppUser from "@/views/appUser/edit";
import BusinessCategoryIndex from "@/views/businessCategory/index";
import AddBusinessCategory from "@/views/businessCategory/add";
import EditBusinessCategory from "@/views/businessCategory/edit";
import JobPostIndex from "@/views/job/jobPost/index";
import AddJobPost from "@/views/job/jobPost/add";
import EditJobPost from "@/views/job/jobPost/edit";
import JobApplicationIndex from "@/views/job/jobApplication/index";
import Settings from "@/views/settings";
import Feedback from "@/views/feedback/index";
import QualificationIndex from "@/views/qualification/index";
import QualificationEdit from "@/views/qualification/edit";
import CancellationIndex from "@/views/cancellationReason/index";
import CancellationEdit from "@/views/cancellationReason/edit";
import FaqIndex from "@/views/faq/index";
import FaqAdd from "@/views/faq/add";
import FaqEdit from "@/views/faq/edit";
import SupportIndex from "@/views/support/index";
import AdvanceSearchIndex from "@/views/advanceSearch/index";
import JobDetail from "@/views/advanceSearch/jobDetail";
import NotificationIndex from "@/views/notification/index";
import NotificationAdd from "@/views/notification/add";
import GigIndex from "@/views/gig/index";
import AddGig from "@/views/gig/add";
import EditGig from "@/views/gig/edit";
import WebsiteContent from "@/views/websiteContent/index";
import ViewSupport from "@/views/support/edit.vue";
import IndexForm from "@/views/form/index.vue";
import AddForm from "@/views/form/add.vue";
import EditForm from "@/views/form/edit.vue";
import Suggestion from "@/views/suggestion/index";
import FormSubmission from "@/views/form/formSubmission";
import FormDetail from "@/views/form/formDetail";
import ContactUs from "@/views/contactUs/index"

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    component: Body,
    children: [
      {
        path: "/dashboard/:msg?",
        component: Dashboard,
        name: "Dashboard",
      },
    ],
  },
  {
    path: "/auth",
    children: [
      {
        path: "login",
        name: "Login",
        component: Login,
        meta: {
          public: true,
        },
      },
      {
        path: "verifyEmail",
        component: VerfiyEmail,
        meta: {
          public: true,
        },
      },
      {
        path: "resetPassword",
        component: ResetPassword,
        meta: {
          public: true,
        },
      },
    ],
  },
  {
    path: "/roles",
    component: Body,
    children: [
      {
        path: "/roles",
        component: RoleIndex,
        meta: {
          module: "role_view",
        },
      },
      {
        path: "/roles/add",
        component: AddRole,
        meta: {
          module: "role_add",
        },
      },
      {
        path: "/roles/edit/:id",
        component: EditRole,
        meta: {
          module: "role_edit",
        },
      },
    ],
  },
  {
    path: "/admins",
    component: Body,
    children: [
      {
        path: "/admins/profile",
        component: AdminProfile,
        meta: {
          module: "admin_view",
        },
      },
      {
        path: "/admins/changePassword/:id?",
        component: AdminChangePassword,
        meta: {
          module: "admin_view",
        },
      },
      {
        path: "/admins",
        component: AdminIndex,
        name: "Admins",
        meta: {
          module: "admin_view",
        },
      },
      {
        path: "/admins/add",
        component: AddAdmin,
        meta: {
          module: "admin_add",
        },
      },
      {
        path: "/admins/edit/:id",
        component: EditAdmin,
        meta: {
          module: "admin_edit",
        },
      },
    ],
  },
  {
    path: "/skills",
    component: Body,
    children: [
      {
        path: "/skills",
        component: SkillIndex,
        meta: {
          module: "skill_view",
        },
      },
      {
        path: "/skills/edit/:id/:parentId?",
        component: EditSkill,
        meta: {
          module: "skill_edit",
        },
      },
    ],
  },
  {
    path: "/locations",
    component: Body,
    children: [
      {
        path: "/locations",
        component: LocationIndex,
        meta: {
          module: "locations_view",
        },
      },
      {
        path: "/locations/add",
        component: AddLocation,
        meta: {
          module: "locations_add",
        },
      },
      {
        path: "/locations/edit/:id/:parentId?",
        component: EditLocation,
        meta: {
          module: "locations_edit",
        },
      },
    ],
  },
  {
    path: "/users",
    component: Body,
    children: [
      {
        path: "/users",
        component: AppUserIndex,
        meta: {
          module: "appusers_view",
        },
      },
      {
        path: "/users/add",
        component: AddAppUser,
        meta: {
          module: "appusers_add",
        },
      },
      {
        path: "/users/edit/:id",
        component: EditAppUser,
        meta: {
          module: "appusers_edit",
        },
      },
    ],
  },
  {
    path: "/businessCategories",
    component: Body,
    children: [
      {
        path: "/businessCategories",
        component: BusinessCategoryIndex,
        meta: {
          module: "bus_cat_view",
        },
      },
      {
        path: "/businessCategories/add",
        component: AddBusinessCategory,
        meta: {
          module: "bus_cat_add",
        },
      },
      {
        path: "/businessCategories/edit/:id",
        component: EditBusinessCategory,
        meta: {
          module: "bus_cat_edit",
        },
      },
    ],
  },
  {
    path: "/jobPosts",
    component: Body,
    children: [
      {
        path: "/jobPosts",
        component: JobPostIndex,
        meta: {
          module: "job_post_view",
        },
      },
      {
        path: "/jobPosts/add",
        component: AddJobPost,
        meta: {
          module: "job_post_add",
        },
      },
      {
        path: "/jobPosts/edit/:id",
        component: EditJobPost,
        meta: {
          module: "job_post_edit",
        },
      },
    ],
  },
  {
    path: "/jobApplications",
    component: Body,
    children: [
      {
        path: "/jobApplications",
        component: JobApplicationIndex,
        meta: {
          module: "job_app_view",
        },
      },
    ],
  },
  {
    path: "/settings",
    component: Body,
    children: [
      {
        path: "/settings",
        component: Settings,
        meta: {
          module: "settings",
        },
      },
    ],
  },
  {
    path: "/feedbacks",
    component: Body,
    children: [
      {
        path: "/feedbacks",
        component: Feedback,
        meta: {
          module: "feedback",
        },
      },
    ],
  },
  {
    path: "/suggestions",
    component: Body,
    children: [
      {
        path: "/suggestions",
        component: Suggestion,
        meta: {
          module: "feedback",
        },
      },
    ],
  },
  {
    path: "/qualification",
    component: Body,
    children: [
      {
        path: "/qualification",
        component: QualificationIndex,
        meta: {
          module: "qualification_view",
        },
      },
      {
        path: "/qualification/edit/:id",
        component: QualificationEdit,
        meta: {
          module: "qualification_edit",
        },
      },
    ],
  },
  {
    path: "/cancellation",
    component: Body,
    children: [
      {
        path: "/cancellation",
        component: CancellationIndex,
        meta: {
          module: "cancellation_view",
        },
      },
      {
        path: "/cancellation/edit/:id",
        component: CancellationEdit,
        meta: {
          module: "cancellation_edit",
        },
      },
    ],
  },
  {
    path: "/faq",
    component: Body,
    children: [
      {
        path: "/faq",
        component: FaqIndex,
        meta: {
          module: "faq_view",
        },
      },
      {
        path: "/faq/add",
        component: FaqAdd,
        meta: {
          module: "faq_add",
        },
      },
      {
        path: "/faq/edit/:id",
        component: FaqEdit,
        meta: {
          module: "faq_edit",
        },
      },
    ],
  },
  {
    path: "/support",
    component: Body,
    children: [
      {
        path: "/support",
        component: SupportIndex,
        meta: {
          module: "faq_edit",
        },
      },
      {
        path: "/support/edit/:id",
        component: ViewSupport,
        meta: {
          module: "faq_view",
        },
      },
    ],
  },
  {
    path: "/advance-search",
    component: Body,
    children: [
      {
        path: "/advance-search",
        component: AdvanceSearchIndex,
        meta: {
          module: "advance_search",
        },
      },
      {
        path: "/advance-search/job-detail",
        component: JobDetail,
        meta: {
          module: "advance_search",
        },
      },
    ],
  },
  {
    path: "/notification",
    component: Body,
    children: [
      {
        path: "/notification",
        component: NotificationIndex,
        meta: {
          module: "notification_view",
        },
      },
      {
        path: "/notification/add",
        component: NotificationAdd,
        meta: {
          module: "notification_add",
        },
      },
    ],
  },
  {
    path: "/skillSet",
    component: Body,
    children: [
      {
        path: "/skillSet",
        component: GigIndex,
        meta: {
          module: "gig_view",
        },
      },
      {
        path: "/skillSet/add",
        component: AddGig,
        meta: {
          module: "gig_add",
        },
      },
      {
        path: "/skillSet/edit/:id",
        component: EditGig,
        meta: {
          module: "gig_edit",
        },
      },
    ],
  },
  {
    path: "/form",
    component: Body,
    children: [
      {
        path: "/form",
        component: IndexForm,
        meta: {
          module: "form_view",
        },
      },
      {
        path: "/form/add",
        component: AddForm,
        meta: {
          module: "form_add",
        },
      },
      {
        path: "/form/edit/:id",
        component: EditForm,
        meta: {
          module: "form_edit",
        },
      },
      {
        path: "/form/submission/:id",
        component: FormSubmission,
        meta: {
          module: "form_view",
        },
      },
      {
        path: "/form/detail/:formId/:id",
        component: FormDetail,
        meta: {
          module: "form_view",
        },
      },
    ],
  },
  {
    path: "/content",
    component: Body,
    children: [
      {
        path: "/content",
        component: WebsiteContent,
        meta: {
          module: "notification_view",
        },
      },
    ],
  },
  {
    path: "/contact-us",
    component: Body,
    children: [
      {
        path: "/contact-us",
        component: ContactUs,
        meta: {
          module: "contact_us",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active",
});
const permissions = localStorage.getItem("permissions");

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem("token");

  if (!to.meta?.public && !isAuthenticated && to.name !== "Login") {
    next({ name: "Login" });
  } else if (
    isAuthenticated &&
    !permissions.includes(to.meta?.module) &&
    to.name !== "Dashboard"
  ) {
    next({ path: `/dashboard/${btoa(to.meta?.module)}` });
  } else {
    next();
  }
});
export default router;
