<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div
      class="modal fade"
      id="addModal"
      tabindex="-1"
      aria-labelledby="addModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addModalLabel">Apply to Job</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="close"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submit">
              <div class="form-group">
                <label>Worker <span class="text-danger">*</span></label>
                <multiselect
                  v-model="application.worker_id"
                  :options="workers"
                  label="full_name"
                ></multiselect>
              </div>
              <div class="form-group mt-3">
                <label>Jobs <span class="text-danger">*</span></label>
                <multiselect
                  v-model="application.job_id"
                  :options="jobs"
                  label="title"
                  @select="selectJob"
                ></multiselect>
              </div>
              <div class="form-group mt-3" v-if="negotiationForm">
                <label>Rate</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="application.rate"
                  @keydown="numberOnly($event)"
                />
              </div>
              <div class="form-group mt-3" v-if="negotiationForm">
                <label>Comment</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="application.comment"
                />
              </div>
              <div class="row mt-3">
                <div class="col-md-12">
                  <button
                    type="submit"
                    class="btn btn-lg btn-primary w-100"
                    v-if="isApply"
                  >
                    Apply
                  </button>
                  <p v-else>The deadline to apply on this has been expired.</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import { isNumber } from "@/helpers/helpers";

export default {
  data() {
    return {
      application: {
        worker_id: null,
        job_id: null,
        status: "Applied",
        rate: null,
        comment: null,
      },
      loading: false,
      workers: [],
      jobs: [],
      negotiationForm: false,
      isApply: true,
    };
  },
  mounted() {
    this.fetchJobs();
    this.fetchWorkers();
  },
  methods: {
    numberOnly(evt) {
      isNumber(evt);
    },
    selectJob() {
      const date = new Date().toISOString().split("T")[0];
      if (date > this.application.job_id.deadline) {
        this.isApply = false;
        this.negotiationForm = false;
      } else {
        this.isApply = true;
        if (this.application.job_id.salary_type == "Negotiable") {
          this.negotiationForm = true;
        } else {
          this.negotiationForm = false;
        }
      }
    },
    fetchJobs() {
      this.loading = true;
      this.$store
        .dispatch("jobPosts/getAllWithoutPagination")
        .then((response) => {
          this.jobs = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    fetchWorkers() {
      this.loading = true;
      const type = "worker";
      this.$store
        .dispatch("userProfiles/getAll", { type })
        .then((response) => {
          this.workers = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    submit() {
      if (!this.application.worker_id) {
        this.$toast.show("Worker is required", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else if (!this.application.job_id) {
        this.$toast.show("Job is required", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        this.loading = true;
        this.application.worker_id = this.application.worker_id.id;
        this.application.job_id = this.application.job_id.id;
        const data = toRaw(this.application);
        this.$store
          .dispatch("jobApplications/store", { data })
          .then((response) => {
            this.loading = false;
            if (response.success === true) {
              this.$toast.show("Successfuly applied", {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 5000,
              });
              this.application.job_id = null;
              this.application.worker_id = null;
              this.application.rate = null;
              this.application.comment = null;
            } else {
              this.$toast.show(response.error, {
                theme: "outline",
                position: "top",
                type: "error",
                duration: 5000,
              });
            }
          })
          .catch((e) => {
            console.log(e.error);
            this.loading = false;
          });
      }
    },
    close() {
      this.$emit("applied");
    },
  },
};
</script>
