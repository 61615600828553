<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5 mx-auto">
        <router-link to="/roles" class="btn btn-sm btn-primary mb-3"
          >Back</router-link
        >
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Add Role</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="addRole">
              <label>Name <span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control mb-3"
                required
                v-model="role.name"
              />
              <div class="row">
                <div class="row">
                  <div class="col-md-9">
                    <div class="row">
                      <h6 class="col-md-3">Admins</h6>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="admin_view"
                          value="admin_view"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="admin_view"
                          >View</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="admin_add"
                          value="admin_add"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="admin_add"
                          >Add</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="admin_edit"
                          value="admin_edit"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="admin_edit"
                          >Edit</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="admin_block"
                          value="admin_block"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="admin_block"
                          >Block</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-3">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="admin_change_password"
                          value="admin_change_password"
                          v-model="role.permissions"
                        />
                        <label
                          class="form-check-label"
                          for="admin_change_password"
                          >Change Password</label
                        >
                      </div>
                    </div>
                    <div class="row">
                      <h6 class="col-md-3">Roles</h6>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="role_view"
                          value="role_view"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="role_view"
                          >View</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="role_add"
                          value="role_add"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="role_add"
                          >Add</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="role_edit"
                          value="role_edit"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="role_edit"
                          >Edit</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="role_delete"
                          value="role_delete"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="role_delete"
                          >Delete</label
                        >
                      </div>
                    </div>
                    <div class="row">
                      <h6 class="col-md-3">Skills</h6>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="skill_view"
                          value="skill_view"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="skill_view"
                          >View</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="skill_add"
                          value="skill_add"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="skill_add"
                          >Add</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="skill_edit"
                          value="skill_edit"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="skill_edit"
                          >Edit</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="skill_delete"
                          value="skill_delete"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="skill_delete"
                          >Delete</label
                        >
                      </div>
                    </div>
                    <div class="row">
                      <h6 class="col-md-3">Locations</h6>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="locations_view"
                          value="locations_view"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="locations_view"
                          >View</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="locations_add"
                          value="locations_add"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="locations_add"
                          >Add</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="locations_edit"
                          value="locations_edit"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="locations_edit"
                          >Edit</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="locations_delete"
                          value="locations_delete"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="locations_delete"
                          >Delete</label
                        >
                      </div>
                    </div>
                    <div class="row">
                      <h6 class="col-md-3">App Users</h6>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="appusers_view"
                          value="appusers_view"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="appusers_view"
                          >View</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="appusers_add"
                          value="appusers_add"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="appusers_add"
                          >Add</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="appusers_edit"
                          value="appusers_edit"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="appusers_edit"
                          >Edit</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="appusers_delete"
                          value="appusers_delete"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="appusers_delete"
                          >Delete</label
                        >
                      </div>
                    </div>
                    <div class="row">
                      <h6 class="col-md-3">Business Categories</h6>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="bus_cat_view"
                          value="bus_cat_view"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="bus_cat_view"
                          >View</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="bus_cat_add"
                          value="bus_cat_add"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="bus_cat_add"
                          >Add</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="bus_cat_edit"
                          value="bus_cat_edit"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="bus_cat_edit"
                          >Edit</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="bus_cat_delete"
                          value="bus_cat_delete"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="bus_cat_delete"
                          >Delete</label
                        >
                      </div>
                    </div>
                    <div class="row">
                      <h6 class="col-md-3">Job Posts</h6>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="job_post_view"
                          value="job_post_view"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="job_post_view"
                          >View</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="job_post_add"
                          value="job_post_add"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="job_post_add"
                          >Add</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="job_post_edit"
                          value="job_post_edit"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="job_post_edit"
                          >Edit</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="job_post_delete"
                          value="job_post_delete"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="job_post_delete"
                          >Delete</label
                        >
                      </div>
                    </div>
                    <div class="row">
                      <h6 class="col-md-3">Job Applications</h6>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="job_app_view"
                          value="job_app_view"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="job_app_view"
                          >View</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="job_app_add"
                          value="job_app_add"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="job_app_add"
                          >Add</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="job_app_edit"
                          value="job_app_edit"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="job_app_edit"
                          >Edit</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="job_app_delete"
                          value="job_app_delete"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="job_app_delete"
                          >Delete</label
                        >
                      </div>
                    </div>
                    <div class="row">
                      <h6 class="col-md-3">Notifications</h6>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="notification_view"
                          value="notification_view"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="notification_view"
                          >View</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="notification_add"
                          value="notification_add"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="notification_add"
                          >New</label
                        >
                      </div>
                    </div>
                    <div class="row">
                      <h6 class="col-md-3">Feedback</h6>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="feedback"
                          value="feedback"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="feedback"
                          >View</label
                        >
                      </div>
                    </div>
                    <div class="row">
                      <h6 class="col-md-3">Settings</h6>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="settings"
                          value="settings"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="settings"
                          >Manage</label
                        >
                      </div>
                    </div>
                    <div class="row">
                      <h6 class="col-md-3">Qualification</h6>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="qualification_view"
                          value="qualification_view"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="qualification_view"
                          >View</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="qualification_add"
                          value="qualification_add"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="qualification_add"
                          >Add</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="qualification_edit"
                          value="qualification_edit"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="qualification_edit"
                          >Edit</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="qualification_delete"
                          value="qualification_delete"
                          v-model="role.permissions"
                        />
                        <label
                          class="form-check-label"
                          for="qualification_delete"
                          >Delete</label
                        >
                      </div>
                    </div>
                    <div class="row">
                      <h6 class="col-md-3">Cancellation Reasons</h6>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="cacellation_view"
                          value="cacellation_view"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="cacellation_view"
                          >View</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="cacellation_add"
                          value="cacellation_add"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="cacellation_add"
                          >Add</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="cacellation_edit"
                          value="cacellation_edit"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="cacellation_edit"
                          >Edit</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="cacellation_delete"
                          value="cacellation_delete"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="cacellation_delete"
                          >Delete</label
                        >
                      </div>
                    </div>
                    <div class="row">
                      <h6 class="col-md-3">FAQs</h6>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="faq_view"
                          value="faq_view"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="faq_view"
                          >View</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="faq_add"
                          value="faq_add"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="faq_add"
                          >Add</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="faq_edit"
                          value="faq_edit"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="faq_edit"
                          >Edit</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="faq_delete"
                          value="faq_delete"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="faq_delete"
                          >Delete</label
                        >
                      </div>
                    </div>
                    <div class="row">
                      <h6 class="col-md-3">Skill-set</h6>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="gig_view"
                          value="gig_view"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="gig_view"
                          >View</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="gig_add"
                          value="gig_add"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="gig_add"
                          >Add</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="gig_edit"
                          value="gig_edit"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="gig_edit"
                          >Edit</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="gig_delete"
                          value="gig_delete"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="gig_delete"
                          >Delete</label
                        >
                      </div>
                    </div>
                    <div class="row">
                      <h6 class="col-md-3">Support</h6>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="support"
                          value="support"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="support"
                          >View</label
                        >
                      </div>
                    </div>
                    <div class="row">
                      <h6 class="col-md-3">Contact Us</h6>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="contact"
                          value="contact_us"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="contact"
                          >View</label
                        >
                      </div>
                    </div>
                    <!-- <div class="row">
                      <h6 class="col-md-3">Advance Search</h6>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="advance_search"
                          value="advance_search"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="advance_search"
                          >View</label
                        >
                      </div>
                    </div> -->
                    <div class="row">
                      <h6 class="col-md-3">Dynamic Forms</h6>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="form_view"
                          value="form_view"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="form_view"
                          >View</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="form_add"
                          value="form_add"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="form_add"
                          >Add</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="form_edit"
                          value="form_edit"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="form_edit"
                          >Edit</label
                        >
                      </div>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="form_delete"
                          value="form_delete"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="form_delete"
                          >Delete</label
                        >
                      </div>
                    </div>
                    <div class="row">
                      <h6 class="col-md-3">Website Content</h6>
                      <div class="form-check form-check-inline col-md-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="web_content"
                          value="web_content"
                          v-model="role.permissions"
                        />
                        <label class="form-check-label" for="web_content"
                          >Manage</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-9">
                  <button type="submit" class="btn btn-lg btn-primary w-100">
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <button
                    type="button"
                    class="btn btn-lg btn-outline-danger w-100"
                    @click="resetForm"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";

export default {
  data() {
    return {
      role: {
        name: "",
        permissions: [],
      },
      loading: false,
    };
  },
  methods: {
    submit() {
      this.loading = true;
      const data = toRaw(this.role);
      this.$store
        .dispatch("roles/store", { data })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.$toast.show("Successfuly added", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            this.role.name = "";
            this.role.permissions = [];
          } else {
            this.$toast.show("Failed to save", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
    resetForm() {
      this.role.name = "";
      this.role.permissions = [];
    },
  },
};
</script>
