<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mt-5 mx-auto">
        <div class="col-md-12">
          <button @click="goBack" class="btn btn-sm btn-primary mb-3">
            Back
          </button>
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Edit Skill</h4>
            </div>
            <div class="card-body">
              <form @submit.prevent="submit" ref="editSkill">
                <div class="form-group row">
                  <div class="col-md-12">
                    <label>Name <span class="text-danger">*</span></label>
                    <span class="text-danger d-block" v-if="errors.name">
                      {{ errors.name }}
                    </span>
                    <input
                      type="text"
                      class="form-control mb-3"
                      required
                      v-model="skill.name"
                    />
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-9">
                    <button type="submit" class="btn btn-lg btn-primary w-100">
                      Save
                    </button>
                  </div>
                  <div class="col-md-3">
                    <button
                      type="reset"
                      class="btn btn-lg btn-outline-danger w-100"
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="col-md-12 mt-3" v-if="parent">
          <div class="card">
            <div class="card-body">
              <button
                class="btn btn-sm btn-primary mb-2"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#addModal"
              >
                Add Skill
              </button>
              <data-table
                v-if="skill.children"
                :columns="columns"
                :items="skill.children"
                :module="module"
                :isEdit="isEdit"
                :isDelete="isDelete"
                @deleted="fetch(skillId)"
                @nextPage="nextPage"
                @search="fetch(skillId)"
                @clear="fetch(skillId)"
              ></data-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="addModal"
      tabindex="-1"
      aria-labelledby="addModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Add Skill</h4>
          </div>
          <div class="modal-body">
            <form @submit.prevent="addSkill" ref="addSkill">
              <div class="form-group row">
                <div class="col-md-12">
                  <label>Name <span class="text-danger">*</span></label>
                  <span class="text-danger d-block" v-if="skillErrors.name">
                    {{ skillErrors.name }}
                  </span>
                  <input
                    type="text"
                    class="form-control mb-3"
                    required
                    v-model="newSkill.name"
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <button type="submit" class="btn btn-lg btn-primary w-100">
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <button
                    type="reset"
                    class="btn btn-lg btn-outline-danger w-100"
                  >
                    Reset
                  </button>
                </div>
                <div class="col-md-3">
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    class="btn btn-lg btn-outline-primary w-100"
                  >
                    Close
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import dataTable from "@/components/dataTable.vue";
import router from "@/router";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    dataTable,
  },
  data() {
    return {
      skill: [],
      errors: [],
      skillErrors: [],
      loading: false,
      skillId: null,
      columns: [
        {
          text: "Name",
          value: "name",
        },
      ],
      module: "skills",
      newSkill: {
        name: null,
        parentId: null,
        createdBy: localStorage.getItem("uid"),
      },
      parent: true,
      isEdit: true,
      isDelete: true,
    };
  },
  created() {
    if (!isNaN(parseInt(this.$route.params.parentId))) {
      this.parent = false;
    }
  },
  mounted() {
    this.skillId = this.$route.params.id;
    this.fetch(this.skillId);
  },
  methods: {
    nextPage(url) {
      this.loading = true;
      axiosInstance.get(url).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
    goBack() {
      router.go(-1);
    },
    submit() {
      this.loading = true;
      this.errors = [];
      const data = toRaw(this.skill);
      const id = this.skillId;
      this.$store
        .dispatch("skills/update", { data, id })
        .then(() => {
          this.loading = false;
          this.$toast.show("Successfuly updated.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.$refs.editSkill.reset();
          this.errors = [];
        })
        .catch((e) => {
          this.loading = false;
          this.errors = e.error;
        });
    },
    addSkill() {
      this.loading = true;
      this.errors = [];
      this.newSkill.parentId = this.skill.id;
      const data = toRaw(this.newSkill);
      this.$store
        .dispatch("skills/store", { data })
        .then(() => {
          this.loading = false;
          this.$toast.show("Successfuly added.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.newSkill.name = null;
          this.skillErrors = [];
          this.fetch(this.skillId);
        })
        .catch((e) => {
          this.loading = false;
          this.skillErrors = e.error;
        });
    },
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("skills/getSingle", { id })
        .then((response) => {
          this.skill = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>
