<template>
  <div class="container-fluid">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="card mt-5">
          <div class="card-body">
            <h3 class="mb-5 text-center">Website Content</h3>
            <form @submit.prevent="submit">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Title</label>
                    <input
                      type="text"
                      class="form-control mb-3"
                      placeholder="Title"
                      v-model="website.section1_title"
                    />
                    <label>Description</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Short Description"
                      v-model="website.section1_description"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <img
                    src="@/assets/images/content/1.png"
                    style="width: 100%"
                  />
                </div>
              </div>
              <hr />

              <div class="col-md-12">
                <img src="@/assets/images/content/2.png" style="width: 100%" />
                <div class="row mt-3">
                  <div class="col-md-4">
                    <img
                      v-if="section2Image1Preivew"
                      :src="section2Image1Preivew"
                      class="img-thumbnail mb-3"
                      style="width: 100px; height: 100px"
                    />
                    <input
                      type="file"
                      ref="section2Image1"
                      class="form-control mb-3"
                      accept="image/png, image/jpg, image/jpeg"
                      @change="section2Image1Selected"
                    />
                    <label>Title</label>
                    <input
                      type="text"
                      class="form-control mb-3"
                      placeholder="Title"
                      v-model="website.section2[0].title"
                    />
                    <label>Description</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Short Description"
                      v-model="website.section2[0].description"
                    />
                  </div>
                  <div class="col-md-4">
                    <img
                      v-if="section2Image2Preivew"
                      :src="section2Image2Preivew"
                      class="img-thumbnail mb-3"
                      style="width: 100px; height: 100px"
                    />
                    <input
                      type="file"
                      ref="section2Image2"
                      class="form-control mb-3"
                      accept="image/png, image/jpg, image/jpeg"
                      @change="section2Image2Selected"
                    />
                    <label>Title</label>
                    <input
                      type="text"
                      class="form-control mb-3"
                      placeholder="Title"
                      v-model="website.section2[1].title"
                    />
                    <label>Description</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Short Description"
                      v-model="website.section2[1].description"
                    />
                  </div>
                  <div class="col-md-4">
                    <img
                      v-if="section2Image3Preivew"
                      :src="section2Image3Preivew"
                      class="img-thumbnail mb-3"
                      style="width: 100px; height: 100px"
                    />
                    <input
                      type="file"
                      ref="section2Image3"
                      class="form-control mb-3"
                      accept="image/png, image/jpg, image/jpeg"
                      @change="section2Image3Selected"
                    />
                    <label>Title</label>
                    <input
                      type="text"
                      class="form-control mb-3"
                      placeholder="Title"
                      v-model="website.section2[2].title"
                    />
                    <label>Description</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Short Description"
                      v-model="website.section2[2].description"
                    />
                  </div>
                </div>
              </div>
              <hr />

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Title</label>
                    <input
                      type="text"
                      class="form-control mb-3"
                      placeholder="Title"
                      v-model="website.section3_title"
                    />
                    <label>Description</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Short Description"
                      v-model="website.section3_description"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <img
                    src="@/assets/images/content/3.png"
                    style="width: 100%"
                  />
                </div>
              </div>
              <hr />

              <div class="col-md-12">
                <img src="@/assets/images/content/4.png" style="width: 100%" />
                <div class="row mt-3">
                  <div class="col-md-4">
                    <label>Title</label>
                    <input
                      type="text"
                      class="form-control mb-3"
                      placeholder="Title"
                      v-if="website.section4"
                      v-model="website.section4[0].title"
                    />
                    <label>Description</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Short Description"
                      v-if="website.section4"
                      v-model="website.section4[0].description"
                    />
                  </div>
                  <div class="col-md-4">
                    <label>Title</label>
                    <input
                      type="text"
                      class="form-control mb-3"
                      placeholder="Title"
                      v-if="website.section4"
                      v-model="website.section4[1].title"
                    />
                    <label>Description</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Short Description"
                      v-if="website.section4"
                      v-model="website.section4[1].description"
                    />
                  </div>
                  <div class="col-md-4">
                    <label>Title</label>
                    <input
                      type="text"
                      class="form-control mb-3"
                      placeholder="Title"
                      v-if="website.section4"
                      v-model="website.section4[2].title"
                    />
                    <label>Description</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Short Description"
                      v-if="website.section4"
                      v-model="website.section4[2].description"
                    />
                  </div>
                </div>
              </div>
              <hr />

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Title</label>
                    <input
                      type="text"
                      class="form-control mb-3"
                      placeholder="Title"
                      v-model="website.section5_title"
                    />
                    <label>Description</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Short Description"
                      v-model="website.section5_description"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <img
                    src="@/assets/images/content/5.png"
                    style="width: 100%"
                  />
                </div>
              </div>
              <hr />

              <div class="col-md-12">
                <img src="@/assets/images/content/6.png" style="width: 100%" />
                <div class="row mt-3">
                  <div class="col-md-4">
                    <label>Title</label>
                    <input
                      type="text"
                      class="form-control mb-3"
                      placeholder="Title"
                      v-if="website.section6"
                      v-model="website.section6[0].title"
                    />
                    <label>Description</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Short Description"
                      v-if="website.section6"
                      v-model="website.section6[0].description"
                    />
                  </div>
                  <div class="col-md-4">
                    <label>Title</label>
                    <input
                      type="text"
                      class="form-control mb-3"
                      placeholder="Title"
                      v-if="website.section6"
                      v-model="website.section6[1].title"
                    />
                    <label>Description</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Short Description"
                      v-if="website.section6"
                      v-model="website.section6[1].description"
                    />
                  </div>
                  <div class="col-md-4">
                    <label>Title</label>
                    <input
                      type="text"
                      class="form-control mb-3"
                      placeholder="Title"
                      v-if="website.section6"
                      v-model="website.section6[2].title"
                    />
                    <label>Description</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Short Description"
                      v-if="website.section6"
                      v-model="website.section6[2].description"
                    />
                  </div>
                </div>
              </div>
              <hr />

              <div class="row">
                <div class="col-md-6">
                  <input
                    type="file"
                    ref="video"
                    class="form-control"
                    accept="video/mp4"
                    @change="videoSelected"
                  />
                  <video
                    class="mt-3 w-50"
                    id="video-preview"
                    controls
                    v-show="videoPreview"
                  ></video>
                </div>
                <div class="col-md-6">
                  <img
                    src="@/assets/images/content/7.png"
                    style="width: 100%"
                  />
                </div>
              </div>
              <hr />

              <div class="col-md-12">
                <img src="@/assets/images/content/8.png" style="width: 100%" />
                <div class="row mt-3">
                  <div class="col-md-4">
                    <img
                      v-if="section8Image1Preview"
                      :src="section8Image1Preview"
                      class="img-thumbnail mb-3"
                      style="width: 100px; height: 100px"
                    />
                    <input
                      type="file"
                      ref="section8Image1"
                      class="form-control mb-3"
                      accept="image/png, image/jpg, image/jpeg"
                      @change="section8Image1Selected"
                    />
                    <label>Client Name</label>
                    <input
                      type="text"
                      class="form-control mb-3"
                      placeholder="Client Name"
                      v-if="website.section8"
                      v-model="website.section8[0].name"
                    />
                    <label>Desgination</label>
                    <input
                      type="text"
                      class="form-control mb-3"
                      placeholder="Designation"
                      v-if="website.section8"
                      v-model="website.section8[0].designation"
                    />
                    <label>Testimonial</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Testimonial"
                      v-if="website.section8"
                      v-model="website.section8[0].testimonial"
                    />
                  </div>
                  <div class="col-md-4">
                    <img
                      v-if="section8Image2Preview"
                      :src="section8Image2Preview"
                      class="img-thumbnail mb-3"
                      style="width: 100px; height: 100px"
                    />
                    <input
                      type="file"
                      ref="section8Image2"
                      class="form-control mb-3"
                      accept="image/png, image/jpg, image/jpeg"
                      @change="section8Image2Selected"
                    />
                    <label>Client Name</label>
                    <input
                      type="text"
                      class="form-control mb-3"
                      placeholder="Client Name"
                      v-if="website.section8"
                      v-model="website.section8[1].name"
                    />
                    <label>Designation</label>
                    <input
                      type="text"
                      class="form-control mb-3"
                      placeholder="Designation"
                      v-if="website.section8"
                      v-model="website.section8[1].designation"
                    />
                    <label>Testimonial</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Testimonial"
                      v-if="website.section8"
                      v-model="website.section8[1].testimonial"
                    />
                  </div>
                  <div class="col-md-4">
                    <img
                      v-if="section8Image3Preview"
                      :src="section8Image3Preview"
                      class="img-thumbnail mb-3"
                      style="width: 100px; height: 100px"
                    />
                    <input
                      type="file"
                      ref="section8Image3"
                      class="form-control mb-3"
                      accept="image/png, image/jpg, image/jpeg"
                      @change="section8Image3Selected"
                    />
                    <label>Client Name</label>
                    <input
                      type="text"
                      class="form-control mb-3"
                      placeholder="Client Name"
                      v-if="website.section8"
                      v-model="website.section8[2].name"
                    />
                    <label>Designation</label>
                    <input
                      type="text"
                      class="form-control mb-3"
                      placeholder="Designation"
                      v-if="website.section8"
                      v-model="website.section8[2].designation"
                    />
                    <label>Testimonial</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Testimonial"
                      v-if="website.section8"
                      v-model="website.section8[2].testimonial"
                    />
                  </div>
                </div>
              </div>
              <hr />

              <button type="submit" class="btn btn-primary float-end">
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { validateFileFormat } from "@/helpers/helpers";
import { toRaw } from "vue";

export default {
  data() {
    return {
      loading: false,
      section2: [
        {
          image: null,
          title: null,
          description: null,
        },
        {
          image: null,
          title: null,
          description: null,
        },
        {
          image: null,
          title: null,
          description: null,
        },
      ],
      section4: [
        {
          title: null,
          description: null,
        },
        {
          title: null,
          description: null,
        },
        {
          title: null,
          description: null,
        },
      ],
      section6: [
        {
          title: null,
          description: null,
        },
        {
          title: null,
          description: null,
        },
        {
          title: null,
          description: null,
        },
      ],
      section8: [
        {
          image: null,
          name: null,
          designation: null,
          testimonial: null,
        },
        {
          image: null,
          name: null,
          designation: null,
          testimonial: null,
        },
        {
          image: null,
          name: null,
          designation: null,
          testimonial: null,
        },
      ],
      website: {
        section1_title: null,
        section1_description: null,
        section3_title: null,
        section3_description: null,
        section5_title: null,
        section5_description: null,
        video: null,
        section2: [],
        section4: [],
        section6: [],
        section8: [],
      },
      section2Image1: null,
      section2Image2: null,
      section2Image3: null,
      section2Image1Preivew: null,
      section2Image2Preivew: null,
      section2Image3Preivew: null,
      videoPreview: null,
      section8Image1: null,
      section8Image2: null,
      section8Image3: null,
      section8Image1Preview: null,
      section8Image2Preview: null,
      section8Image3Preview: null,
    };
  },
  created() {
    this.website.section2 = this.section2;
    this.website.section4 = this.section4;
    this.website.section6 = this.section6;
    this.website.section8 = this.section8;
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$store
        .dispatch("website/getAll")
        .then((response) => {
          if (response.data != null) {
            this.website = response.data;
            if (!this.website.section2) {
              this.website.section2 = this.section2;
            }
            if (!this.website.section4) {
              this.website.section4 = this.section4;
            }
            if (!this.website.section6) {
              this.website.section6 = this.section6;
            }
            if (!this.website.section8) {
              this.website.section8 = this.section8;
            }
            if (this.website.section2 && this.website.section2[0].image_url) {
              this.section2Image1Preivew = this.website.section2[0].image_url;
            }
            if (this.website.section2 && this.website.section2[1].image_url) {
              this.section2Image2Preivew = this.website.section2[1].image_url;
            }
            if (this.website.section2 && this.website.section2[2].image_url) {
              this.section2Image3Preivew = this.website.section2[2].image_url;
            }
            if (this.website.section8 && this.website.section8[0].image_url) {
              this.section8Image1Preview = this.website.section8[0].image_url;
            }
            if (this.website.section8 && this.website.section8[1].image_url) {
              this.section8Image2Preview = this.website.section8[1].image_url;
            }
            if (this.website.section8 && this.website.section8[2].image_url) {
              this.section8Image3Preview = this.website.section8[2].image_url;
            }
          }
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.website);
      if (this.website.id) {
        const id = this.website.id;
        this.$store
          .dispatch("website/update", { data, id })
          .then((response) => {
            this.loading = false;
            this.$toast.show("Successfuly saved.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
          })
          .catch((e) => {
            this.loading = false;
            console.log(e);
          });
      } else {
        this.$store
          .dispatch("website/store", { data })
          .then((response) => {
            this.loading = false;
            this.$toast.show("Successfuly saved.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
          })
          .catch((e) => {
            this.loading = false;
            console.log(e);
          });
      }
    },
    section2Image1Selected() {
      this.section2Image1 = this.$refs.section2Image1.files[0];
      if (validateFileFormat(this.section2Image1, "image")) {
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.section2Image1Preivew = e.target.result;
        };
        reader.onloadend = () => {
          this.website.section2[0].image = reader.result;
        };
        reader.readAsDataURL(this.section2Image1);
      }
    },
    section2Image2Selected() {
      this.section2Image2 = this.$refs.section2Image2.files[0];
      if (validateFileFormat(this.section2Image2, "image")) {
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.section2Image2Preivew = e.target.result;
        };
        reader.onloadend = () => {
          this.website.section2[1].image = reader.result;
        };
        reader.readAsDataURL(this.section2Image2);
      }
    },
    section2Image3Selected() {
      this.section2Image3 = this.$refs.section2Image3.files[0];
      if (validateFileFormat(this.section2Image3, "image")) {
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.section2Image3Preivew = e.target.result;
        };
        reader.onloadend = () => {
          this.website.section2[2].image = reader.result;
        };
        reader.readAsDataURL(this.section2Image3);
      }
    },
    section8Image1Selected() {
      this.section8Image1 = this.$refs.section8Image1.files[0];
      if (validateFileFormat(this.section8Image1, "image")) {
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.section8Image1Preview = e.target.result;
        };
        reader.onloadend = () => {
          this.website.section8[0].image = reader.result;
        };
        reader.readAsDataURL(this.section8Image1);
      }
    },
    section8Image2Selected() {
      this.section8Image2 = this.$refs.section8Image2.files[0];
      if (validateFileFormat(this.section8Image2, "image")) {
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.section8Image2Preview = e.target.result;
        };
        reader.onloadend = () => {
          this.website.section8[1].image = reader.result;
        };
        reader.readAsDataURL(this.section8Image2);
      }
    },
    section8Image3Selected() {
      this.section8Image3 = this.$refs.section8Image3.files[0];
      if (validateFileFormat(this.section8Image3, "image")) {
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.section8Image3Preview = e.target.result;
        };
        reader.onloadend = () => {
          this.website.section8[2].image = reader.result;
        };
        reader.readAsDataURL(this.section8Image3);
      }
    },
    videoSelected() {
      let video = document.getElementById("video-preview");
      this.videoPreview = this.$refs.video.files[0];
      if (validateFileFormat(this.videoPreview, "video")) {
        this.videoPreview = null;
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          video.src = e.target.result;
        };
        reader.onloadend = () => {
          this.website.video = reader.result;
        };
        reader.readAsDataURL(this.videoPreview);
      }
    },
  },
};
</script>
