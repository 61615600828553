<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="card mt-5">
        <div class="card-body">
          <h3 class="mb-5">Settings</h3>
          <h6 class="border-bottom">General Settings</h6>
          <div class="row mt-3">
            <div class="col-md-6">
              <h6>Auto Approve App Users</h6>
              <p>
                This setting allows you to enable or disable automatically
                approve newly registered app user.
              </p>
            </div>
            <div
              class="col-md-6 d-flex justify-content-center align-items-center"
            >
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="app-user"
                  v-model="appUsers"
                />
                <label class="form-check-label" for="app-user">Enable</label>
              </div>
            </div>
          </div>
          <h6 class="border-bottom mt-3">Terms & Privacy Policy</h6>
          <div class="row mt-3 mb-5">
            <div class="col-md-6">
              <h6>Terms and Conditions</h6>
              <p>
                This setting allows you to enable or disable automatically
                approve newly registered app user.
              </p>
            </div>
            <div class="col-md-6">
              <quill-editor v-model:value="terms" :options="options" />
            </div>
          </div>
          <br>
          <div class="row mt-3 mb-5">
            <div class="col-md-6">
              <h6>Privacy Policy</h6>
              <p>
                This setting allows you to enable or disable automatically
                approve newly registered app user.
              </p>
            </div>
            <div class="col-md-6">
              <quill-editor v-model:value="policy" :options="options" />
            </div>
          </div>
          <button type="button" class="btn btn-primary float-end" @click="save">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      appUsers: false,
      terms: null,
      policy: null,
      options: {
        modules: {
          toolbar: [
            "bold",
            "italic",
            "underline",
            { list: "ordered" },
            { list: "bullet" },
          ],
        },
      },
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$store
        .dispatch("settings/getAll")
        .then((response) => {
          this.loading = false;
          response.data.forEach((item) => {
            if (item.key == "app-users-auto-approve") {
              this.appUsers = item.value;
            }
            if (item.key == "terms") {
              this.terms = item.value;
            }
            if (item.key == "policy") {
              this.policy = item.value;
            }
          });
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    save() {
      this.loading = true;
      const data = [
        {
          key: "app-users-auto-approve",
          value: this.appUsers,
        },
        {
          key: "terms",
          value: this.terms,
        },
        {
          key: "policy",
          value: this.policy,
        },
      ];
      this.$store
        .dispatch("settings/update", { data })
        .then(() => {
          this.loading = false;
          this.$toast.show("Successfuly updated.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show("Error occured.", {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 5000,
          });
          console.log(e);
        });
    },
  },
};
</script>
