<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-10 mt-5 mx-auto">
        <router-link to="/form" class="btn btn-sm btn-primary mb-3"
          >Back</router-link
        >
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Add Form</h4>
          </div>
          <div class="card-body">
            <form
              @submit.prevent="submit"
              id="form"
              class="needs-validation"
              novalidate
            >
              <div class="form-group mb-3">
                <label>Form Title <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.title"
                  required
                />
                <div class="invalid-feedback text-danger">
                  Form Title is required
                </div>
              </div>

              <button
                type="button"
                class="btn btn-sm btn-primary mb-3"
                @click="addQuestion"
              >
                Add Question
              </button>
              <template v-for="(question, index) in form.questions">
                <div class="form-group mb-3">
                  <div class="row justify-content-between align-items-end">
                    <label class="w-auto"
                      >Question {{ index + 1 }} Title
                      <span class="text-danger">*</span></label
                    >
                    <button
                      type="button"
                      class="btn btn-sm btn-danger mb-3 me-3 w-auto"
                      @click="removeQuestion(index)"
                    >
                      Remove Question
                    </button>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    v-model="question.title"
                    required
                  />
                  <div class="invalid-feedback text-danger">
                    Question {{ index + 1 }} Title is required
                  </div>
                </div>
                <div class="form-group">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :id="'required' + index"
                      true-value="1"
                      false-value="0"
                      v-model="question.is_required"
                    />
                    <label class="form-check-label" :for="'required' + index"
                      >Required</label
                    >
                  </div>
                </div>
                <div class="form-group mb-3">
                  <label>Options Type <span class="text-danger">*</span></label>
                  <select
                    v-model="question.type"
                    class="select-form-control"
                    required
                    @change="checkOptionType(index, question.type)"
                  >
                    <option value="">Select</option>
                    <option value="text">Text</option>
                    <option value="checkbox">Checkbox</option>
                    <option value="radio">Radio</option>
                  </select>
                  <div class="invalid-feedback text-danger">
                    Options Type is required
                  </div>
                </div>
                <div class="form-group row">
                  <div
                    class="col-md-6 mb-3"
                    v-for="(option, index) in question.options"
                  >
                    <label
                      >Option {{ index + 1 }} Title
                      <span class="text-danger" v-if="index < 2">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      v-model="option.title"
                      :required="index < 2"
                    />
                    <div class="invalid-feedback text-danger">
                      Option {{ index + 1 }} Title is required
                    </div>
                  </div>
                </div>
              </template>

              <button type="submit" class="btn btn-primary float-end mt-3">
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      form: {
        title: null,
        questions: [],
      },
      question: {
        title: null,
        type: "",
        is_required: 0,
        has_options: 0,
        options: [],
      },
      option: {
        title: null,
      },
    };
  },
  mounted() {
    this.addQuestion();
  },
  methods: {
    checkOptionType(index, type) {
      if (type == "checkbox" || type == "radio") {
        this.form.questions[index].options = [];
        this.form.questions[index].has_options = 1;
        for (let i = 0; i < 4; i++) {
          this.addOptions(index);
        }
      } else {
        this.form.questions[index].has_options = 0;
        this.form.questions[index].options = [];
      }
    },
    addQuestion() {
      this.form.questions.push(Object.assign({}, this.question));
    },
    removeQuestion(index) {
      this.form.questions.splice(index, 1);
    },
    addOptions(index) {
      this.form.questions[index].options.push(Object.assign({}, this.option));
    },
    submit() {
      let form = document.getElementById("form");
      if (form.checkValidity() == false) {
        form.classList.add("was-validated");
      } else {
        this.loading = true;
        const data = this.form;
        this.$store
          .dispatch("form/store", { data })
          .then((response) => {
            this.loading = false;
            if (response.success == true) {
              this.$toast.show("Successfuly added.", {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 5000,
              });
              this.form.title = null;
              this.form.questions = [];
              this.addQuestion();
            } else {
              this.$toast.show("Error saving data.", {
                theme: "outline",
                position: "top",
                type: "error",
                duration: 5000,
              });
            }
          })
          .catch((e) => {
            console.log(e);
            this.loading = false;
          });
      }
    },
  },
};
</script>
