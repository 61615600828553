<template>
  <loading :active="loading" :is-full-page="true" loader="bars"></loading>

  <div
    class="modal fade"
    :id="'sendFormModal' + index"
    tabindex="-1"
    aria-labelledby="sendFormModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <h5>Send Form</h5>
          <form @submit.prevent="sendForm">
            <div class="form-group">
              <label class="d-block"
                >Send To <span class="text-danger">*</span></label
              >
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  id="worker"
                  value="worker"
                  name="send-to"
                  v-model="sendTo"
                  required
                />
                <label class="form-check-label" for="worker">Workers</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  id="employer"
                  value="employer"
                  name="send-to"
                  v-model="sendTo"
                  required
                />
                <label class="form-check-label" for="employer">Employers</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  id="company"
                  value="company"
                  name="send-to"
                  v-model="sendTo"
                  required
                />
                <label class="form-check-label" for="company">Companies</label>
              </div>
            </div>
            <button
              type="button"
              class="btn btn-sm btn-secondary me-2"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button type="submit" class="btn btn-sm btn-primary">Send</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: null,
    formId: null,
  },
  data() {
    return {
      sendTo: null,
      loading: false,
    };
  },
  methods: {
    sendForm() {
      this.loading = true;
      const sendTo = this.sendTo;
      const formId = this.formId;
      this.$store
        .dispatch("form/send", { sendTo, formId })
        .then((response) => {
          this.loading = false;
          this.$toast.show("Successfuly sent", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
  },
};
</script>
