<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="card mt-5">
        <div class="card-body">
          <h3 class="text-center">Dynamic Forms</h3>
          <div class="col-md-12 d-flex justify-content-end">
            <router-link to="/form/add" class="btn btn-sm btn-primary mb-2"
              >Add Form</router-link
            >
          </div>
          <div class="col-md-12">
            <data-table
              :columns="columns"
              :items="items"
              :isEdit="true"
              :isDelete="true"
              :module="module"
              :sendForm="true"
              :viewForm="true"
              @deleted="fetch"
              @nextPage="nextPage"
              @search="fetch"
              @clear="fetch"
            ></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dataTable from "@/components/dataTable.vue";

export default {
  components: {
    dataTable,
  },
  data() {
    return {
      loading: false,
      items: [],
      columns: [
        {
          text: "Title",
          value: "title",
        },
      ],
      module: "form",
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    nextPage(url) {
      this.loading = true;
      axiosInstance.get(url).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
    fetch(search = "") {
      this.loading = true;
      this.$store
        .dispatch("form/getAll", { search })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>
