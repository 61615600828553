<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="card mt-5">
        <div class="card-body">
          <h3 class="text-center">Notifications</h3>
          <div class="col-md-12 d-flex justify-content-end">
            <router-link
              to="/notification/add"
              class="btn btn-sm btn-primary mb-2"
              v-if="permissions.includes('notification_add')"
              >Send Notification</router-link
            >
          </div>
          <div class="col-md-12">
            <data-table
              :columns="columns"
              :module="module"
              :items="items"
            ></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataTable from "@/components/dataTable.vue";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      searchValue: "",
      columns: [
        {
          text: "Title",
          value: "title",
        },
        {
          text: "Detail",
          value: "body",
        },
      ],
      items: [],
      module: "admins",
      loading: false,
      changePassword: false,
      permissions: [],
      viewDetail: true,
    };
  },
  created() {
    this.permissions = localStorage.getItem("permissions");
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$store
        .dispatch("notifications/getAll")
        .then((response) => {
          this.items = response;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>
